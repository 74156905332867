import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import {Dialog, Backdrop, Paper} from '@material-ui/core';
import {Loader} from 'wobi-web-common';
import {FormattedMessage, injectIntl} from 'react-intl';
import LoaderImage from '../../assets/images/loader.gif';
import productTypes from '../../consts/product-type';
import {mapRequestData} from '../../utils/offerFieldsActions';
import {checkOfferStatus, getCoversTitles, getAllOffers} from '../../utils/apiHandlers';
import {getOffersFields, setOffersFields} from '../../utils/persistOfferDetails';
import Offer from '../../components/Offer';
import MissingFieldsForm from '../../components/MissingFieldsForm';
import OffersQuickActions from '../../components/OffersQuickActions';
import {ORDER_ID} from '../../consts/storageKeys';
import NoOffers from '../../components/NoOffers';
import {sortingOptions} from '../../config/offersSort';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const CHECK_STATUS_TIMEOUT = process.env.REACT_APP_CHECK_STATUS_TIMEOUT || 2000;
const CHECK_STATUS_TIME_LIMIT = process.env.REACT_APP_CHECK_STATUS_TIME_LIMIT || 10000;
const ENV_MODE = process.env.REACT_APP_ENV_MODE || 'local';

const generateOrderId = (offersCallId) => `${moment().format('mmHHDDMM')}-${offersCallId.slice(-4)}`;

class OffersStep extends Component {
  state = {
    coversTitles: {},
    highestCoverage: 0,
    isTypeChangeDialogOpen: false,
    newPolicyType: '',
    keepChecking: true,
    loader: false,
    lowestPrice: 0,
    lowParticipation: 0,
    offers: [],
    offersCallId: '',
    offersFields: getOffersFields(),
    openOfferIndex: 0,
    showContactUs: false,
    sortTypeId: 1,
  };

  componentDidMount() {
    fireGtmEvent('homeOffers');
    this.getOffers();
  }

  handler = (actionType, values) => {
    switch (actionType) {
    case 'sort': {
      const sortTypeId = values;
      const comparer = sortingOptions.find(sortingOption => sortingOption.id === sortTypeId).comparer;
      this.setState(prevState => ({
        offers: prevState.offers.sort(comparer),
        sortTypeId,
      }));
      break;
    }
    case 'type': {
      const {offersFields} = this.state;
      const structureToContent = offersFields.policyType === productTypes.Structure &&
        values !== productTypes.Structure &&
        !getOffersFields('residentsNumber');
      const contentToStructure = offersFields.policyType === productTypes.Content &&
        values !== productTypes.Content &&
        !getOffersFields('squareMeters');
      if (structureToContent || contentToStructure) {
        this.setState({isTypeChangeDialogOpen: true, newPolicyType: values});
      } else {
        setOffersFields({policyType: values});
        this.getNewOffers();
        this.setState({offersFields: getOffersFields()});
      }
      break;
    }
    default:
      break;
    }
  };

  setOpenOffer = (index) => {
    this.setState({openOfferIndex: index});
  };

  getTitles = async () => {
    const resp = await getCoversTitles(getOffersFields('policyType'));
    if (resp && resp.data) {
      this.setState({coversTitles: resp.data.coversTitles});
    }
  };

  checkStatus = async () => {
    try {
      const resp = await checkOfferStatus({
        offersCallId: this.state.offersCallId,
      });
      if (resp.data?.responses?.length) {
        const respOffers = resp.data.responses;
        const lowest = (valA, valB) => !valA ? valB : valB < valA ? valB : valA;
        this.setState({
          highestCoverage: respOffers
            .reduce((highestCoverage, {additionalPrices}) => {
              const cVal = additionalPrices[1]?.value + (additionalPrices[2]?.value || 0);
              return !highestCoverage ? cVal : cVal > highestCoverage ? cVal : highestCoverage;
            }, 0),
          lowestPrice: respOffers
            .reduce((lowestPrice, {price}) => lowest(lowestPrice, price), 0),
          lowParticipation: respOffers
            .reduce((lowParticipation, {additionalPrices}) => lowest(lowParticipation, additionalPrices[0]?.value), 0),
          offers: respOffers,
        }, () => {
          this.handler('sort', this.state.sortTypeId);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  initTimer = () => {
    this.setState({
      keepChecking: true,
    }, () => {
      this.statusInterval = setInterval(() => {
        if (this.state.keepChecking) {
          this.checkStatus();
        } else {
          this.setState({
            keepChecking: false,
            loader: false,
          });
          clearInterval(this.statusInterval);
        }
      }, CHECK_STATUS_TIMEOUT);
      this.timeout = setTimeout(() => {
        this.setState({
          keepChecking: false,
          loader: false,
        });
        clearInterval(this.statusInterval);
      }, CHECK_STATUS_TIME_LIMIT);
    });
  };

  getOffers = async () => {
    const defaultLoaderValue = ENV_MODE?.includes('local');

    this.setState({
      keepChecking: true,
      loader: defaultLoaderValue,
      offers: [],
    });
    const requestData = mapRequestData(getOffersFields());

    this.setState({offers: []});
    try {
      const resp = await getAllOffers(requestData);
      if (resp.data) {
        const offerCallIdState = {offersCallId: resp.data.offersCallId};
        setOffersFields(offerCallIdState);
        sessionStorage.setItem(ORDER_ID, generateOrderId(resp.data.offersCallId));
        this.setState(offerCallIdState);
      }
    } catch (error) {
      console.log(error);
    }
    this.initTimer();
  };

  getNewOffers = () => {
    clearInterval(this.statusInterval);
    clearTimeout(this.timeout);
    this.setState({
      isTypeChangeDialogOpen: false,
      openOfferIndex: 0,
    });
    this.getOffers();
  };

  toggleContactUs = (status) => {
    this.setState({showContactUs: status});
  };

  calcIsBestOffer = (offer) => {
    const sortTypeId = this.state.sortTypeId;
    return sortTypeId === 1 ? offer.price === this.state.lowestPrice :
      sortTypeId === 2 ? offer.additionalPrices[1]?.value +
      (offer.additionalPrices[2]?.value || 0) === this.state.highestCoverage :
        sortTypeId === 3 ? offer.additionalPrices[0]?.value === this.state.lowParticipation : false;
  }

  render() {
    const {classes, intl} = this.props;
    const messages = (id) => intl.formatMessage({id});
    const {
      offers,
      loader,
      offersFields,
      coversTitles,
      openOfferIndex,
      showContactUs,
      sortTypeId,
      keepChecking,
      isTypeChangeDialogOpen,
      newPolicyType,
    } = this.state;

    return (
      <section>
        {offers.length ? (
          <React.Fragment>
            <div className={classes.summaryText}>
              <FormattedMessage id='offers.number_of_offers_text' values={{
                name: `${getOffersFields('firstname')}`,
                number_of_offers: offers.length,
              }} />
            </div>
            <div className={classes.newClients}>
              <FormattedMessage id='offers.new_clients_only' />
            </div>
          </React.Fragment>
        ) : false}
        <div className={`${classes.offersStep}`}>
          <Backdrop className={classes.backdrop} open={loader}>
            <Paper style={{padding: 30}}>
              <Loader image={LoaderImage}>
                מגוון הצעות בדרך...
              </Loader>
            </Paper>
          </Backdrop>
          {offers.length ? (
            <>
              <OffersQuickActions criteriaChanged={this.handler} offersFields={offersFields} />
              <div className={`${classes.offersStepOffers}`}>
                {offers.map((offer, index) => (
                  <Offer
                    titles={coversTitles}
                    key={`offer-${index}`}
                    setIsOpen={() => this.setOpenOffer(index)}
                    openContactUs={() => this.toggleContactUs(true)}
                    closeContactUsHandler={() => this.toggleContactUs(false)}
                    expandCoversHandler={() => fireGtmEvent('homeShowAllCoverage', {provider: offer.provider})}
                    isBestOffer={this.calcIsBestOffer(offer)}
                    bestOfferBadgeText={messages(`offers.bestOfferBadgeText[${sortTypeId}]`)}
                    showContactUs={index === openOfferIndex && showContactUs}
                    data={{
                      ...offer,
                      pdfLink: `/policies/${offer.productLineId}/policy.pdf`,
                    }}
                    isOpen={openOfferIndex === index}
                    pdfLinkHandler={() => {}}
                  />
                ))}
              </div>
            </>
          ) : !keepChecking && !offers.length ? (
            <NoOffers />
          ) : false}
          <Dialog
            onClose={() => this.setState({isTypeChangeDialogOpen: false})}
            open={isTypeChangeDialogOpen}
          >
            <MissingFieldsForm
              getOffers={this.getNewOffers}
              oldPolicyType={offersFields.policyType}
              newPolicyType={newPolicyType}
            />
          </Dialog>
        </div>
      </section>
    );
  }
}

OffersStep.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object,
};

export default withStyles(useStyles)(injectIntl(OffersStep));
