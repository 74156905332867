import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {SvgIcon} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import {injectIntl} from 'react-intl';
import {ReactComponent as ApartmentIcon} from 'assets/images/apartment.svg';
import {ReactComponent as HouseIcon} from 'assets/images/house.svg';

const useStyles = makeStyles(theme => ({
  icon: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0,
    height: 80,
    padding: 5,
    width: 80,
  },
  checkedIcon: {
    border: `6px solid ${theme.palette.secondary.mainDark}`,
    padding: 0,
  },
  label: {
    textAlign: 'center',
  },
  root: {
    '&.Mui-focusVisible, &:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    padding: 0,
    margin: 9,
  },
}));

const ResidenceTypeSelector = ({intl, value, onChange}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth component='fieldset'>
      <RadioGroup row name='residenceType' value={value} onChange={onChange} data-testid='residence-selector-radio'>
        <div>
          <Radio
            className={classes.root}
            disableRipple
            color='default'
            checkedIcon={
              <SvgIcon
                className={[classes.icon, classes.checkedIcon].join(' ')}
                component={ApartmentIcon}
                viewBox='0 0 120.35 120.35'/>
            }
            icon={<SvgIcon className={classes.icon} component={ApartmentIcon} viewBox='0 0 120.35 120.35'/>}
            id='residenceTypeApartment'
            value='apartment'
          />
          <label htmlFor='residenceTypeApartment' className={classes.label}>
            <Typography>{intl.formatMessage({id: 'details.apartment'})}</Typography>
          </label>
        </div>
        <div>
          <Radio
            className={classes.root}
            disableRipple
            color='default'
            checkedIcon={
              <SvgIcon
                className={[classes.icon, classes.checkedIcon].join(' ')}
                component={HouseIcon}
                viewBox='0 0 120.35 120.35'/>
            }
            icon={<SvgIcon className={classes.icon} component={HouseIcon} viewBox='0 0 120.35 120.35'/>}
            id='residenceTypeHouse'
            value='house'
          />
          <label htmlFor='residenceTypeHouse' className={classes.label}>
            <Typography>{intl.formatMessage({id: 'details.house'})}</Typography>
          </label>
        </div>
      </RadioGroup>
    </FormControl>
  );
};

ResidenceTypeSelector.propTypes = {
  intl: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default injectIntl(ResidenceTypeSelector);
