import {makeStyles} from '@material-ui/core';
import {Breakpoints as breakpoints} from 'wobi-web-common';

const useStyles = makeStyles({
  contents: {
    padding: '0 50px',
    width: '100%',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      padding: '0 0 20px',
    },
  },
  hide: {
    display: 'none',
  },
  root: {
    display: 'flex',
  },
  tabs: {
    flex: 'none',
    width: 213,
  },
});

export default useStyles;
