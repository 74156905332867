import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import useStyles from './useStyles';

const a11yProps = (index) => ({
  'aria-controls': `vertical-tabpanel-${index}`,
  id: `vertical-tab-${index}`,
  key: `tab-${index}`,
});

const TabsLayout = (
  {
    currentTabIndex,
    handleChange,
    tabs,
    formikSetTouched,
    children,
    formikTouched,
    formikHandleChange,
    formikSetField,
    formikHandleBlur,
    formikErrors,
    formikResetForm,
    formikValues,
    formikIsSubmitting,
    formikHandleSubmit,
    formikValidateFrom,
    isFormValid,
  },
) => {
  const classes = useStyles();
  const isDesktop = !useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  const isComplete = (index) => {
    if (index < currentTabIndex) {
      return 'complete';
    }
    return undefined;
  };

  const TabsContainer = (
    <Tabs
      className={classes.tabs}
      orientation='vertical'
      variant='scrollable'
      value={currentTabIndex}
      onChange={handleChange}
      aria-label='wobi-tabs'>
      {tabs.map((item, index) => (
        <Tab key={index} className={isComplete(index)}
          label={index === currentTabIndex ? (<h1>{item.label}</h1>) : item.label} {...a11yProps(index)} />
      ))}
    </Tabs>
  );

  return (
    <div className={classes.root}>
      {
        isDesktop ? TabsContainer : ''
      }
      <div className={classes.contents}>
        { children && children.length ?
          children.map((item, index) => React.cloneElement(item, {
            className: index === currentTabIndex ? '' : classes.hide,
            formikErrors,
            formikHandleBlur,
            formikHandleChange,
            formikHandleSubmit,
            formikIsSubmitting,
            formikResetForm,
            formikSetField,
            formikSetTouched,
            formikTouched,
            formikValidateFrom,
            formikValues,
            isFormValid,
            key: `tab-content-${index}`,
          })) : false}
      </div>
    </div>
  );
};

TabsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
  currentTabIndex: PropTypes.number,
  formikErrors: PropTypes.object,
  formikHandleBlur: PropTypes.func,
  formikHandleChange: PropTypes.func,
  formikHandleSubmit: PropTypes.func,
  formikIsSubmitting: PropTypes.bool,
  formikResetForm: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikTouched: PropTypes.any,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  handleChange: PropTypes.func,
  isFormValid: PropTypes.bool,
  maxIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
  })),

};

export default TabsLayout;
