import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: 20,
  },
  withClaimTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down(1330)]: {
      '& .MuiFormControlLabel-root': {
        marginBottom: 15,
      },
      '& .MuiFormGroup-root': {
        width: 266,
      },
      alignItems: 'flex-start',
      flexFlow: 'column nowrap',
    },
  },
  insuranceClaimTooltip: {
    [theme.breakpoints.up(1330)]: {
      marginLeft: 20,
    },
    '&>.icon.Alert': {
      backgroundPositionY: 'center',
      height: 30,
    },
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
      },
    },
  },
}));

export default useStyles;
