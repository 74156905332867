import {makeStyles} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints} from 'wobi-web-common';

const useStyles = makeStyles(() => ({
  callMeBackButton: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'space-between',
    marginTop: 30,
    width: '200px',
  },
  contactUsThanks: {
    '& img': {
      width: 80,
    },
    alignItems: 'center',
    border: `1px solid ${Black[1]}`,
    borderRadius: 10,
    display: 'inline-flex',
    margin: '20px 0',
    padding: '15px 40px',
  },
  contactUsThanksText: {
    color: Black[170],
    fontSize: 18,
    marginLeft: 20,
  },
  imgContainer: {
    '& > img': {
      maxWidth: 500,
      width: '100%',
    },
    alignSelf: 'flex-end',
    padding: '20px 45px 20px 0',
    textAlign: 'center',
    width: '100%',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {padding: '10px'},
  },
  messageTop: {
    color: Black[170],
    fontSize: '24px',
    marginBottom: '20px',
  },
}));

export default useStyles;
