import React from 'react';
import PropTypes from 'prop-types';
import {OtpPopup, agentDeveloperUtils} from 'wobi-web-common';
import {getOffersFields, setOffersFields} from '../utils/persistOfferDetails';
import {getToken, validateSecret} from '../utils/apiHandlers';
import {fireGtmEvent} from '../utils/marketingUtils';

const OtpContainer = ({open, handleClose, handleVerified, handleBlocked}) => {
  const [tokenReceived, setTokenReceived] = React.useState(false);
  const [tokenLoading, setTokenLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [commonError, setCommonError] = React.useState('');
  const [token, setToken] = React.useState(false);
  const retryCount = React.useRef(0);

  const handlePostOtpDetails = details => {
    setTokenLoading(true);
    fireGtmEvent('homeSendOtp');
    details.agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName;
    details.webOTP = true;
    getToken({...details, retryCount: retryCount.current})
      .then(res => {
        setOffersFields({
          firstname: details.fullname.split(' ')[0],
          lastname: details.fullname.split(' ')[1],
          phone: details.phoneNumber,
          retryCount: retryCount.current,
        });
        if (res.success) {
          setToken(res.data);
          setOffersFields({
            token: res.data,
          });
          setTokenLoading(false);
          setTokenReceived(true);
        } else if (!res.success && res.message === 'blocked user') {
          handleClose();
          handleBlocked();
          setTokenLoading(false);
        }
      })
      .catch(() => {
        setTokenLoading(false);
        setCommonError('Something wrong on details request');
      });
  };

  const handleVerify = data => {
    const request = Object.assign({}, {secret: data.code, token});
    setVerifyLoading(true);
    fireGtmEvent('homeConfirmOtp');
    validateSecret(request)
      .then(res => {
        setVerifyLoading(false);
        if (res.success) {
          handleClose();
          handleVerified();
        } else if (!res.success && res.message === 'wrongOtp') {
          setCommonError('קוד לא נכון אנא וודא את מספר הטלפון');
        }
      })
      .catch(() => {
        setVerifyLoading(false);
        setCommonError('ישנה בעיה בפרטי הבקשה');
      });
  };

  const handleResend = () => {
    retryCount.current++;
    fireGtmEvent('homeOtpSendAgain');
    getToken({
      agentDeveloperName: agentDeveloperUtils.getAgentDeveloperName,
      fullname: `${getOffersFields('firstname')} ${getOffersFields(
        'lastname',
      )}`,
      phoneNumber: getOffersFields('phone'),
      retryCount: retryCount.current,
      webOTP: true,
    })
      .then(res => {
        if (res.success) {
          setToken(res.data);
          setOffersFields({token: res.data});
        }
      })
      .catch(() => {
        setCommonError('ישנה בעיה בפרטי הבקשה');
      });
  };

  const handleBack = () => {
    fireGtmEvent('homeOtpOtherPhone');
    setTokenReceived(false);
  };

  return (
    <OtpPopup
      open={open}
      tokenReceived={tokenReceived}
      commonErrorMessage={commonError}
      onClose={handleClose}
      handlePostOtpDetails={handlePostOtpDetails}
      handleVerify={handleVerify}
      handleResend={handleResend}
      handleBack={handleBack}
      tokenLoading={tokenLoading}
      verifyLoading={verifyLoading}
    />
  );
};

OtpContainer.propTypes = {
  handleBlocked: PropTypes.func,
  handleClose: PropTypes.func,
  handleVerified: PropTypes.func,
  open: PropTypes.bool,
};

export default OtpContainer;
