import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Button, CircularProgress, makeStyles} from '@material-ui/core';
import Coffee from '../assets/images/coffee.svg';
import NoOffersImage from '../assets/images/no-offers.svg';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {getOffersFields} from '../utils/persistOfferDetails';
import {CONTACT_US_SOURCES} from '../consts';

const useStyles = makeStyles(theme => ({
  noOffers: {
    padding: 10,
  },
  contactUsLoader: {
    margin: '30px 70px',
  },
  imgContainer: {
    marginTop: 50,
    '& img': {
      maxWidth: 478,
      width: '100%',
    },
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(21),
  },
  button: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'space-between',
    marginTop: 30,
  },
}));

const NoOffers = ({intl}) => {
  const [isContactUsLoading, setIsContactUsLoading] = React.useState(false);
  const [showContactUsThanks, setShowContactUsThanks] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    // fireGtmEvent('NoOffersPage');
  }, []);

  const submitContactUs = async () => {
    setIsContactUsLoading(true);
    await handleContactUsSubmit({
      ...getOffersFields(),
      isFromNoOffers: true,
    }, CONTACT_US_SOURCES.NO_OFFERS);
    setIsContactUsLoading(false);
    setShowContactUsThanks(true);
  };

  return (
    <div className={classes.noOffers}>
      <div className={classes.text}>
        {intl.formatMessage({id: 'no_offers.text1'})}
        <br/>
        {intl.formatMessage({id: 'no_offers.text2'})}
        <br/>
        {intl.formatMessage({id: 'no_offers.text3'})}
      </div>
      <div>
        {showContactUsThanks ? (
          <div className={classes.contactUsThanks}>
            <img src={Coffee} className='coffee' alt='coffee'/>
            <div>
              <div>{intl.formatMessage({id: 'no_offers.contact_us_accepted1'})}</div>
              <div>{intl.formatMessage({id: 'no_offers.contact_us_accepted2'})}</div>
            </div>
          </div>
        ) : isContactUsLoading ? (
          <CircularProgress className={classes.contactUsLoader} size={40}/>
        ) : (
          <Button variant='outlined' onClick={submitContactUs} className={classes.button}>
            {intl.formatMessage({id: 'no_offers.come_back_to_me'})}
          </Button>
        )}
      </div>
      <div className={classes.imgContainer}>
        <img src={NoOffersImage} alt='no-offers'/>
      </div>
    </div>
  );
};

NoOffers.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object,
  isContactUsLoading: PropTypes.bool,
  showContactUsThanks: PropTypes.bool,
  submitContactUs: PropTypes.func,
};

export default injectIntl(NoOffers);
