const ProductType = {
  Content: 'CONTENT',
  Mixed: 'MIXED',
  Structure: 'STRUCTURE',
};

export const isContent = type => {
  if (type) {
    return type === ProductType.Content;
  }
  return false;
};

export const isStructure = type => {
  if (type) {
    return type === ProductType.Structure;
  }
  return false;
};

export const isMixed = type => {
  if (type) {
    return type === ProductType.Mixed;
  }
  return false;
};

export default ProductType;
