import {makeStyles} from '@material-ui/core/styles';
import {Black, Blue, Orange} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import SecuredIcon from '../../assets/images/credit-card.svg';

const useStyles = makeStyles(theme => ({
  callUs: {
    '& img': {
      position: 'relative',
      top: -2,
    },
    '&:hover': {
      '& span': {
        color: theme.palette.type === 'light' ? Blue[300] : Blue[90],
      },
      backgroundColor: 'transparent',
      boxShadow: 'unset',
    },
    '&>span': {
      marginLeft: 2,
    },
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    color: theme.palette.type === 'light' ? Black[500] : theme.palette.text.primary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(13.125),
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: 20,
    minWidth: 167,
    padding: 0,
    width: '35%',
  },
  yesNoGroupParent: {
    display: 'flex',
    flexDirection: 'row',
  },
  yesNoGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .MuiFormControl-fullWidth': {
      '& >:first-child': {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },

    },
  },
  noOwner: {
    width: '250px',
  },
  materialsTooltip: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    },
  },
  callUsStrong: {
    fontWeight: 700,
    margin: '0 6px',
  },
  creditCardHelp: {
    fontSize: theme.typography.pxToRem(10.5),
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12.25),
  },
  includedText: {
    '&:before': {
      backgroundColor: '#B8D1DE',
      borderRadius: 6,
      content: '""',
      display: 'block',
      height: 6,
      marginRight: 6,
      minWidth: 6,
      width: 6,
    },
    '&:first-child': {
      bottom: 50,
      letterSpacing: '-0.4px',
      marginTop: 20,
    },
    '@media screen and (max-width: 1097px)': {
      margin: '7px 0',
      position: 'static',
    },
    alignItems: 'center',
    bottom: 25,
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '14px',
    position: 'absolute',
  },
  includedWrapper: {
    '@media screen and (max-width: 1097px)': {
      flexBasis: '100%',
      height: 'auto',
      marginRight: 8,
      position: 'static',
    },
    height: 114,
    maxWidth: 225,
    position: 'relative',
  },
  loaderContainer: {
    '& .contact_us_loader': {
      fill: Orange[450],
      margin: 8,
    },
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  paymentBtn: {
    boxShadow: 'unset',
    fontWeight: 700,
    minWidth: 167,
    padding: '6px 22px',
    width: '100%',
  },
  paymentBtnInitWrapper: {
    '@media screen and (min-width: 1024px)': {
      flexFlow: 'column nowrap',
    },
    alignItems: props => props.isMobile ? 'center' : 'flex-start',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 191,
  },
  protection: {
    '&:before': {
      color: Orange[450],
      content: '"*"',
      fontSize: theme.typography.pxToRem(16.6),
      left: -1,
      position: 'absolute',
      top: -1,
    },
    paddingLeft: 10,
    position: 'relative',
  },
  protectionWrapper: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(11.4),
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 10,
  },
  protectionWrapperOpen: {
    justifyContent: 'center',
  },
  purchaseContainer: {
    marginTop: 10,
    padding: '0 20px',
    [`@media screen and (min-width: ${breakpoints.mobile}px) and (max-width: 1093px)`]: {
      padding: 0,
    },
  },
  securedPayment: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 13,
    minWidth: 167,
    width: '50%',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      marginTop: 6,
    },
  },
  securedPaymentInner: {
    '&:before': {
      backgroundImage: `url(${SecuredIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 20px',
      content: '""',
      height: 20,
      margin: '3px 0 0 10px',
      width: 16,
    },
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: 500,
    lineHeight: '30px',
    width: 300,
  },
  tooltip: {
    '&:hover': {
      opacity: 1,
    },
    color: theme.palette.secondary.main,
    opacity: 0.7,
    transition: '0.3s all',
  },
  tooltipWrap: {
    marginLeft: 8,
  },
  verifyLoader: {
    position: 'absolute',
    right: 190,
    top: 5,
  },
}));

export default useStyles;
