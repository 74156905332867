import {Black} from 'wobi-web-common/dist/Colors';

const useStyles = theme => ({
  backdrop: {
    zIndex: 9,
  },
  contactUsThanks: {
    '& > div': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(15.75),
      marginLeft: 20,
    },
    '& img': {
      width: 80,
    },
    alignItems: 'center',
    border: `1px solid ${Black[1]}`,
    borderRadius: 10,
    display: 'inline-flex',

    margin: '20px 0',

    padding: '15px 40px',
  },
  loaderContainer: {
    '&.hide': {
      margin: 0,
      maxHeight: 0,
      transition: 'max-height 0.75s ease-out',
    },
    maxHeight: 300,
    overflow: 'hidden',

    transition: 'max-height 0.75s ease-in',
  },
  newClients: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 500,
    marginBottom: 20,
    marginTop: 5,
  },
  offersStep: {
    margin: '0 auto',
    maxWidth: 1180,
  },
  offersStepOffers: {
    '&>*:not(:last-child)': {
      marginBottom: 15,
    },
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    [theme.breakpoints.down(768)]: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: '15px 0',
    },
    padding: 15,
  },
  summaryText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24.5),
    fontWeight: 600,
  },
});

export default useStyles;
