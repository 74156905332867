import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Grid,
  Button,
  Popper,
  Fade,
} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import productTypes from '../../consts/product-type';
import {ExpandIcon, CollapseIcon} from '../Icons';
import {getOffersFields} from '../../utils/persistOfferDetails';
import {sortingOptions} from '../../config/offersSort';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const ToggledState = (initialState, preToggleMethod) => {
  const [state, setState] = React.useState(initialState);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    if (preToggleMethod) {
      preToggleMethod();
    }
    setState(previousOpen => !previousOpen);
  };

  return [
    state,
    setState,
    anchorRef,
    handleToggle,
  ];
};

const ToggledIcon = open => open ? <CollapseIcon/> : <ExpandIcon/>;

const QuickActionButton = (isOpen, refAnchor, clickHandler, title, className) => (
  <Button
    className={`${isOpen ? 'open' : ''} ${className}`}
    variant='text'
    color='secondary'
    ref={refAnchor}
    aria-controls={isOpen ? 'menu-list-grow' : undefined}
    aria-haspopup='true'
    onClick={clickHandler}
  >
    {title}
    {ToggledIcon(isOpen)}
  </Button>
);

const OffersQuickActions = ({criteriaChanged, intl}) => {
  const messages = (id) => intl.formatMessage({id});
  const [selected, setSelected] = React.useState({
    sort: sortingOptions[0].id,
    type: getOffersFields().policyType,
  });

  const [typeOpen, setTypeOpen, typeAnchorRef, handleTypeToggle] = ToggledState(false, () => {
    setSortOpen(false);
  });
  const [sortOpen, setSortOpen, sortAnchorRef, handleSortToggle] = ToggledState(false, () => {
    setTypeOpen(false);
  });

  const handleChange = (actionType, value) => {
    switch (actionType) {
    case 'sort': {
      fireGtmEvent('homeSortBy', {value});
      setSelected(Object.assign(selected, {sort: value}));
      break;
    }
    case 'type': {
      fireGtmEvent('homePolicyTypeChanged', {selectedPolicyType: value});
      break;
    }
    default:
      break;
    }

    setSortOpen(false);
    setTypeOpen(false);

    criteriaChanged(actionType, value);
  };

  const classes = useStyles();

  const getTypeTitle = () => {
    if (!selected.type) {
      return '';
    }
    return messages(selected.type.toLowerCase());
  };

  return (
    <div className={classes.offersQuickContainer}>
      <Grid container className={classes.boxedContainer}>
        <Grid container item xs={12} sm={8} alignItems='center'>
          <Grid item xs={12} sm={4} lg='auto'>
            {QuickActionButton(typeOpen, typeAnchorRef, handleTypeToggle, getTypeTitle(), 'type')}
          </Grid>
          <Grid item xs={12} sm={4} lg='auto'>
            <span className={classes.houseInfo}>
              <FormattedMessage
                id='house_info'
                values={{
                  city: getOffersFields('city'),
                  house: getOffersFields('residenceType') === 'apartment' ? 'משותף' : 'בית פרטי',
                }}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4} alignItems='center' className={classes.sortBox}>
          <Grid item sm={3} md='auto'>
            <span>מיין לפי</span>
          </Grid>
          <Grid item sm={9} md='auto'>
            {QuickActionButton(
              sortOpen,
              sortAnchorRef,
              handleSortToggle,
              sortingOptions.find(option => option.id === selected.sort).title,
              'sort',
            )}
          </Grid>
        </Grid>
      </Grid>
      <Popper className={`${classes.typePopper} ${classes.popper}`} open={typeOpen} anchorEl={typeAnchorRef.current}
        role={undefined} transition disablePortal placement='bottom-start'>
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <div className={`${classes.paperContent}`}>
              {
                Object.values(productTypes).filter(type => type !== selected.type).map(type => (
                  <MenuItem key={`type-${type}`} onClick={() => handleChange('type', type)}>
                    {messages(type.toLowerCase())}
                  </MenuItem>
                ))
              }

            </div>
          </Fade>)}
      </Popper>
      <Popper className={`${classes.popper} ${classes.sortPopper}`} open={sortOpen} anchorEl={sortAnchorRef.current}
        role={undefined} transition disablePortal placement='bottom-end'>
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <div className={`${classes.paperContent}`}>
              {sortingOptions.filter(option => option.id !== selected.sort).map(sortOption => (
                <MenuItem key={sortOption.id}
                  onClick={() => handleChange('sort', sortOption.id)}>
                  {sortOption.title}
                </MenuItem>
              ))}
            </div>
          </Fade>)}
      </Popper>
    </div>);
};

OffersQuickActions.propTypes = {
  criteria: PropTypes.object,
  criteriaChanged: PropTypes.func,
  intl: PropTypes.object,
  offers: PropTypes.array,
  offersFields: PropTypes.object,
};

export default injectIntl(OffersQuickActions);
