import rollbar from 'rollbar';
import pjson from '../../package.json';

const rollbarToken = process.env.REACT_APP_ROLLBAR_TOKEN;

export default rollbar.init({
  accessToken: rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore(isUncaught, args, payload) {
    // if the request url is not using for sanity check then sending error data to rollbar, else - ignore it
    return payload.request.url.includes('https://wobi-pr');
  },

  enabled: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development',

  payload: {
    client: {
      javascript: {
        code_version: pjson.version,
        guess_uncaught_frames: true,

        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        source_map_enabled: true,
      },
    },
    environment: process.env.NODE_ENV,
  },
});
