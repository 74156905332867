import Moment from 'moment';
import {CAMPAIGN_ARGS, OFFERS_FIELDS_KEY, SELECTED_OFFER_KEY, SESSION_TIMESTAMP} from '../consts/storageKeys';
import {getFieldsFromObject} from './objectUtils';

export const getFromStorage = (storageKey, key = false) => {
  const dataString = sessionStorage.getItem(storageKey);
  const dataObject = dataString ? JSON.parse(dataString) : {};

  return key ? dataObject[key] : dataObject;
};

export const saveToStorage = (storageKey, newData = {}, isSimulator = false) => {
  const oldDataObject = getFromStorage(storageKey, false, isSimulator);
  sessionStorage.setItem(storageKey, JSON.stringify(Object.assign(oldDataObject, newData)));
  sessionStorage.setItem(SESSION_TIMESTAMP, Moment().format());
};

export const setOffersFields = (newData = {}) => saveToStorage(OFFERS_FIELDS_KEY, newData);
export const getOffersFields = (key = false) => getFromStorage(OFFERS_FIELDS_KEY, key);
export const setSelectedOffer = (newData = {}) => saveToStorage(SELECTED_OFFER_KEY, newData);
export const getSelectedOffer = (key = false) => getFromStorage(SELECTED_OFFER_KEY, key);
export const getCampaignArgs = () => getFieldsFromObject(getOffersFields(), CAMPAIGN_ARGS);

export const clearOffersFields = () => {
  const policyType = getPolicyTypeFromStorage();
  sessionStorage.removeItem(OFFERS_FIELDS_KEY);
  sessionStorage.removeItem(SESSION_TIMESTAMP);
  sessionStorage.setItem(OFFERS_FIELDS_KEY, JSON.stringify({policyType}));
};

export const getPolicyTypeFromStorage = () => {
  const dataString = sessionStorage.getItem(OFFERS_FIELDS_KEY);
  const {policyType} = dataString ? JSON.parse(dataString) : {};
  return policyType;
};
