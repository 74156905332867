import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainText: {
    '& span': {
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.pxToRem(29.75),
      fontWeight: 300,
      marginLeft: 10,
    },
    fontSize: theme.typography.pxToRem(31.5),
    fontWeight: 'bold',
  },
  root: {
    color: theme.palette.secondary.main,
    margin: '25px 0',
  },
  subText: {
    '& a': {
      '&:hover': {
        '& span': {color: theme.palette.secondary.dark},
        textDecoration: 'underline',
      },
      color: 'inherit',
      textDecoration: 'none',
    },
    '& span': {
      '&:hover': {color: theme.palette.secondary.dark},
      color: theme.palette.secondary.dark,
      display: 'inline-block',
      fontWeight: 300,
      marginLeft: 12,
    },
    fontSize: theme.typography.fontSize,
  },
}));

export default useStyles;
