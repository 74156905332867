import {localMocks} from './localMocks';

const {REACT_APP_NODE_ENV, REACT_APP_HOME_SERVICE_URL} = process.env;
const protocolPrefix = ['staging', 'local', 'development'].includes(REACT_APP_NODE_ENV) ? 'http://' : 'https://';

const timeoutSignal = (msTimeout = 30000) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), msTimeout);
  return controller.signal;
};

export const backendServicePrefix = `${protocolPrefix}${REACT_APP_HOME_SERVICE_URL}`;

/**
 * Common function for API call request
 * If application running locally, then API request will be mocked
 *
 * @param {object} params - Object of params for API call
 * @param {string} params.endpoint
 * @param {object} [params.data] - Data of call (only for POST)
 * @param {object} [params.headers]
 * @param {string} params.method
 * @param {number} params.timeout
 * @returns {Promise} - Promise with real or mocked call
 */
export const apiRequest = ({endpoint, data, headers, method, timeout}) => {
  if (REACT_APP_NODE_ENV === 'local') {
    const mock = localMocks(endpoint.split('?')[0]);
    if (mock) {
      return Promise.resolve(mock);
    }
  }

  const baseHeaders = {
    'Content-Type': 'application/json',
  };

  return fetch(`${backendServicePrefix}/${endpoint}`, {
    body: JSON.stringify(data),
    headers: Object.assign(baseHeaders, headers),
    method,
    signal: timeout ? timeoutSignal(timeout) : undefined,
  }).then(resp => resp.json());
};

/**
 * API call request with method 'POST'
 *
 * @param {string} endpoint
 * @param {object} [data]
 * @param {object} [headers]
 * @param {number} timeout
 */
export const apiPostRequest = (endpoint, data, headers = {}, timeout) => apiRequest({
  data,
  endpoint,
  headers,
  method: 'POST',
  timeout,
});

/**
 * API call request with method 'GET'
 *
 * @param {string} endpoint
 * @param {object} [headers]
 */
export const apiGetRequest = (endpoint, headers = {}) => apiRequest({
  endpoint,
  headers,
  method: 'GET',
});
