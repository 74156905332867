export const OFFERS_FIELDS_KEY = 'offers_fields';
export const SESSION_TIMESTAMP = 'session_timestamp';
export const SELECTED_OFFER_KEY = 'selected';
export const ORDER_ID = 'order_id';
export const CAMPAIGN_ARGS = [
  'gclid',
  'marketingId',
  'referrer',
  'source',
  'utm_campaign',
  'utm_content',
  'utm_keywords',
  'utm_medium',
  'utm_source',
  'fbclid',
];
