import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {Grid, Box, Table, TableCell, TableRow, TableBody,
  Typography, Button, SnackbarContent, Icon} from '@material-ui/core';
import alertImage from '../../assets/images/alert.svg';
import {getOffersFields, getSelectedOffer} from '../../utils/persistOfferDetails';
import {backendServicePrefix} from '../../utils/apiHelpers';
import {dateDotFormat, dateSlashFormat} from '../../utils/dateTimeHelpers';
import {ORDER_ID} from '../../consts/storageKeys';
import ShippingDetails from '../../components/ShippingDetails';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const ThankYouStep = () => {
  const [isShippingOpen, setIsShippingOpen] = React.useState(true);
  const classes = useStyles();

  React.useEffect(() => {
    fireGtmEvent('homeThankYouPage');
  }, []);

  const policyDetails = {
    policyStartDate: getOffersFields('policyStartDate'),
    policyEndDate: getOffersFields('policyEndDate'),
    ownerFullName: getOffersFields('ownerFullName'),
    offersCallId: getOffersFields('offersCallId'),
    orderId: sessionStorage.getItem(ORDER_ID),
    selected_offer: getSelectedOffer(),
    city: getOffersFields('city'),
    residenceType: getOffersFields('residenceType'),
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Box mb={4} pb={4} borderBottom={1} className={classes.borderColorLight}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className={classes.messageHead} component='h1'>
                <FormattedMessage id='order_approval.thank_you'/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.messageBigLetters}><FormattedMessage id='order_approval.success'/></Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.messageBigLetters}>
                <FormattedMessage id='order_approval.order_number_text'/>
                {' '}
                <span className={classes.colorAccent} data-testid='order-id'>
                  {policyDetails.orderId}
                </span>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item>
              <Box>
                <Typography className={classes.messageRegular}>
                  <FormattedMessage id='order_approval.compulsory_message'/>
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.messageRegular}>
                  <FormattedMessage id='order_approval.shipping_message'/>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box px={4} py={3} mb={1} className={classes.boxAlert}>
                <Box className={classes.boxAlertTitle}>
                  <img
                    className={classes.vAlignMiddle}
                    src={alertImage}
                    alt={<FormattedMessage id='order_approval.alert_title'/>}
                  />
                  <span>
                    {' '}
                    <FormattedMessage id='order_approval.alert_title'/>
                  </span>
                </Box>
                <Box className={classes.boxAlertMessage}><FormattedMessage id='order_approval.alert_message'/></Box>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Typography className={classes.messageSubhead}>
                  <strong><FormattedMessage id='order_approval.table_title'/></strong>
                </Typography>
              </Box>
              <Box>
                <Table className={classes.tableSmall} size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <FormattedMessage id='order_approval.table_label_period'/>
                      </TableCell>
                      <TableCell data-testid='summary-period-value'>
                        <FormattedMessage
                          id='order_approval.table_value_period'
                          values={{
                            end_date: moment(policyDetails.policyEndDate, dateSlashFormat).format(dateDotFormat),
                            start_date: moment(policyDetails.policyStartDate, dateSlashFormat).format(dateDotFormat),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <FormattedMessage id='order_approval.table_label_product_line'/>
                      </TableCell>
                      <TableCell data-testid='summary-product-line-value'>
                        <FormattedMessage
                          id='order_approval.table_value_product_line'
                          values={{
                            productline_title: policyDetails.selected_offer.productLineName,
                            providerName: policyDetails.selected_offer.providerName,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <FormattedMessage id='order_approval.table_label_apartment'/>
                      </TableCell>
                      <TableCell data-testid='summary-apartment-value'>
                        <FormattedMessage
                          id='house_info'
                          values={{
                            city: policyDetails.city,
                            house: policyDetails.residenceType === 'apartment' ? 'משותף' : 'בית פרטי',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <FormattedMessage id='order_approval.table_label_owner'/>
                      </TableCell>
                      <TableCell data-testid='summary-owner-value'>
                        <span>{policyDetails.ownerFullName}</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} container spacing={3} alignItems='center'>
              <Grid item xs={6} md={7}>
                <span className={classes.messagePrimary}>
                  <FormattedMessage id='order_approval.order_confirmation_title'/>
                </span>
              </Grid>
              <Grid item xs={6} md={5}>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='medium'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`${backendServicePrefix}/api/get_policy?token=${policyDetails.offersCallId}`}
                  data-testid='get-order-confirmation'
                >
                  <Icon className={classes.downloadIcon}>get_app</Icon>
                  <FormattedMessage id='order_approval.button_download'/>
                </Button>
              </Grid>
              <Grid item xs={6} md={7}>
                <span className={classes.messagePrimary}>
                  <FormattedMessage id='order_approval.insurance_terms_title'/>
                </span>
              </Grid>
              <Grid item xs={6} md={5}>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='medium'
                  href={`/policies/${policyDetails.selected_offer.productLineId}/policy.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                  data-testid='get-policy-pdf'
                >
                  <Icon className={classes.downloadIcon}>get_app</Icon>
                  <FormattedMessage id='order_approval.button_download'/>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ShippingDetails open={isShippingOpen} onClose={() => setIsShippingOpen(false)}/>
        <Box borderTop={1} className={classes.borderColorGray} pt={2}>
          <SnackbarContent
            className={classes.alertTransparent}
            message={<FormattedMessage id='order_approval.bottom_notification'/>}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ThankYouStep;
