import {getCampaignArgs, getOffersFields} from './persistOfferDetails';

// static for a reason, changing the policyType in mid flow should not effect marketing segment
let originalPolicyType;

export const constructCampaignArgs = (querystringParameters) => {
  if (querystringParameters.utm_campaign) {
    return {
      gclid: querystringParameters.gclid,
      marketingId: querystringParameters.gclid,
      referrer: querystringParameters.referrer,
      source: querystringParameters.utm_source,
      utm_campaign: querystringParameters.utm_campaign,
      utm_content: querystringParameters.utm_content,
      utm_keywords: querystringParameters.utm_keywords || querystringParameters.utm_term,
      utm_medium: querystringParameters.utm_medium,
      utm_source: querystringParameters.utm_source,
    };
  } else if (querystringParameters.gclid) {
    return {
      gclid: querystringParameters.gclid,
      marketingId: querystringParameters.gclid,
      referrer: querystringParameters.referrer,
      source: 'googleAds',
    };
  } else if (querystringParameters.fbclid) {
    return {
      fbclid: querystringParameters.fbclid,
      marketingId: querystringParameters.fbclid,
      referrer: querystringParameters.referrer,
      source: 'facebook',
    };
  } else if (querystringParameters.referrer && !querystringParameters.referrer.includes('wobi.co.il')) {
    return {
      referrer: querystringParameters.referrer,
      source: 'organic',
    };
  } else if (querystringParameters.referrer && querystringParameters.referrer.includes('lm.facebook.com')) {
    return {
      referrer: 'https://lm.facebook.com/',
      source: 'facebook',

    };
  } else if (querystringParameters.referrer && querystringParameters.referrer.includes('LP-')) {
    const segments = querystringParameters.referrer.split('/');
    const parameters = segments[segments.length - 1].split('-');
    if (parameters.length >= 5) {
      return {
        referrer: querystringParameters.referrer,
        utm_campaign: parameters[3],
        utm_content: parameters[4],
        utm_medium: parameters[2],
        utm_source: parameters[1],
      };
    }
  }
  return {
    source: 'direct',
  };
};

export const fireGtmEvent = (gtmEventName, metaData) => {
  const policyType = getOffersFields('policyType');
  const campaignArgs = getCampaignArgs();
  if (!originalPolicyType) {
    originalPolicyType = policyType;
  }
  const data = {
    ...campaignArgs,
    ...metaData,
    event: gtmEventName,
    originalPolicyType,
    policyType,
  };
  window.dataLayer.push(data);
};
