import Moment from 'moment';
import {dateHyphenFormat, dateSlashFormat} from './dateTimeHelpers';

export const conditions = {
  isAboveMin(d, min) {
    return Moment(d).isAfter(min, 'day') || Moment(d).isSame(min, 'day') ||
          Moment(d, dateSlashFormat).isAfter(min, 'day') || Moment(d, dateSlashFormat).isSame(min, 'day');
  },

  isUnderMax(d, max) {
    return Moment(d).isBefore(max, 'day') || Moment(d).isSame(max, 'day') ||
          Moment(d, dateSlashFormat).isBefore(max, 'day') || Moment(d, dateSlashFormat).isSame(max, 'day');
  },

  isValidDate(d) {
    /* when d is 'null' there's another test for it ('required') */
    if (d instanceof Date || d === null) {
      return !Number.isNaN(d);
    } else {
      return Moment(d, dateSlashFormat).isValid() || Moment(d, dateHyphenFormat).isValid();
    }
  },
};
