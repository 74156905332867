import React, {useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

const ScrollToTop = ({history, children}) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

ScrollToTop.propTypes = {
  children: PropTypes.any,
  history: PropTypes.object,
};

export default withRouter(ScrollToTop);
