import {makeStyles} from '@material-ui/core';
import {Black, Orange} from 'wobi-web-common/dist/Colors';

const useStyles = makeStyles(theme => ({
  '.MuiTooltip-touch': {
    fontSize: theme.typography.pxToRem(7.9),
  },
  alertImage: {
    height: 29,
    verticalAlign: 'middle',
    width: 'auto',
  },
  alertText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 600,
  },
  buttonDismiss: {
    '&:after': {
      left: 1,
      top: -1,
      transform: 'rotate(-45deg)',
    },
    '&:before': {
      left: 1,
      top: 1,
      transform: 'rotate(45deg)',
    },
    '&:before, &:after': {
      border: `1px solid ${Black[170]}`,
      content: '""',
      display: 'block',
      position: 'relative',
      width: 25,
      [theme.breakpoints.down('md')]: {
        width: 17,
      },
    },
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      right: 22,
      top: 32,
    },
    position: 'absolute',
    right: 32,
    top: 32,
  },
  dialogSubtitle: {
    color: Orange[450],
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 800,
  },
  dialogTitle: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(33.25),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24.5),
      lineHeight: '34px',
      maxWidth: '80%',
    },
  },
  fullWidthSelect: {
    '& .MuiOutlinedInput-root': '100%',
    width: '100%',
  },
  fullWith: {
    width: '100%',
  },
  helpIcon: {
    '&:hover': {
      opacity: 1,
    },
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(13.125),
    opacity: 0.7,
  },
  iconBack: {
    marginLeft: 15,
    transform: 'rotate(-45deg)',
  },
  modal: {
    '& .MuiPaper-root': {
      borderRadius: 20,
      width: 809,
      [theme.breakpoints.down('md')]: {
        margin: 25,
      },
    },
  },
  submitButton: {
    fontWeight: 800,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  successImage: {
    height: 'auto',
    marginRight: 20,
    width: 60,
  },
  textMutted: {
    color: theme.palette.type === 'light' ? '#9D9D9D' : theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
  },
}));

export default useStyles;
