export const sortingOptions = [
  {
    comparer: ({price: price1}, {price: price2}) => price1 - price2,
    id: 1,
    title: 'מחיר נמוך לגבוה',
  },
  {
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices[1]?.value + (item1.additionalPrices[2]?.value || 0);
      const price2 = item2.additionalPrices[1]?.value + (item2.additionalPrices[2]?.value || 0);

      return price2 - price1;
    },
    id: 2,
    title: 'כיסוי גבוה לנמוך',
  },
  {
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices[0]?.value;
      const price2 = item2.additionalPrices[0]?.value;

      return price1 - price2;
    },
    id: 3,
    title: 'השתתפות עצמית נמוכה לגבוהה',
  },
];
