import {makeStyles} from '@material-ui/core';
import {Orange} from 'wobi-web-common/dist/Colors';

const useStyles = makeStyles(theme => ({
  alertTransparent: {
    backgroundColor: 'transparent !important',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    fontWeight: '500',
  },
  borderColorGray: {
    borderColor: '#707070',
  },
  borderColorLight: {
    borderColor: theme.palette.secondary.light,
  },
  boxAlert: {
    backgroundColor: 'rgba(226, 226, 226, 0.16)',
    fontSize: theme.typography.pxToRem(15.75),
  },
  boxAlertMessage: {
    color: theme.palette.type === 'light' ? '#9D9D9D' : theme.palette.text.secondary,
  },
  boxAlertTitle: {
    color: theme.palette.type === 'light' ? '#8e8e8e' : theme.palette.text.secondary,
    fontWeight: 600,
    marginBottom: 8,
  },
  cellTitle: {
    borderRight: `1px solid ${theme.palette.divider}`,
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  colorAccent: {
    color: Orange[450],
  },
  downloadIcon: {
    color: Orange[450],
    fontSize: theme.typography.pxToRem(17.5),
    marginRight: 5,
  },
  messageBigLetters: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(36.75),
    fontWeight: 300,
    lineHeight: '52px',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  messageHead: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(15.75),
      lineHeight: '28px',
    },
  },
  messagePrimary: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
  },
  messageRegular: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15.75),
  },
  messageSubhead: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 500,
  },
  tableSmall: {
    fontSize: theme.typography.pxToRem(12.25),
  },
  textSmall: {
    fontSize: theme.typography.fontSize,
  },
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
}));

export default useStyles;
