import Moment from 'moment';

export const dateSlashFormat = 'DD/MM/YYYY';
export const dateHyphenFormat = 'YYYY-MM-DD';
export const dateDotFormat = 'D.M.YYYY';

export const nextMonth = Moment().add('1', 'month').startOf('month');
export const nextMonthHyphen = nextMonth.format(dateHyphenFormat);

export const nextMonthsEnd = Moment().add('1', 'month').endOf('month');

export const reformatSlashDateToHyphen = date => Moment(date, dateSlashFormat).format(dateHyphenFormat);

export const getDaysSinceMonthStart = date => Moment(date).diff(Moment(date).startOf('month'), 'days');

export const getDaysToMonthEnd = date => Moment(date).endOf('month').diff(Moment(date), 'days');

export const getDaysInMonth = date => Moment(date).endOf('month').format('DD');

export const getDayFromDate = date => Moment(date).format('DD');

export const currentDateHyphen = Moment().format(dateHyphenFormat);

export const getPolicyEndDate = date => Moment(date)
  .add(1, 'year')
  .subtract(1, 'month')
  .endOf('month')
  .format(dateSlashFormat);

export const todayHyphen = Moment().format(dateHyphenFormat);
