import {makeStyles} from '@material-ui/core';
import {Blue} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints} from 'wobi-web-common';

const useStyles = makeStyles(theme => ({
  boxedContainer: {
    '& Button': {
      border: '1px solid transparent',
      fontWeight: 'bold',
      lineHeight: 1,
      position: 'relative',
    },

    '& button.open': {
      border: `1px solid ${Blue[100]}`,
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRadius: 7,
      boxShadow: '0px 2px 6px #0000001F',
    },

    '& button.open:after': {
      backgroundColor: theme.palette.background.paper,
      bottom: -1,
      content: '""',
      height: 1,
      position: 'absolute',
      width: '100%',
      zIndex: 9,
    },
  },
  houseInfo: {
    marginRight: 10,
  },
  fullWidth: {
    width: '100%',
  },
  offersQuickContainer: {
    position: 'relative',
    zIndex: 2,
  },
  paperContent: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${Blue[100]}`,
  },
  popper: {
    '& > div': {
      borderRadius: 12,
      padding: 10,
    },

    '& li': {
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? Blue[100] : theme.palette.background.default,
      },
      borderRadius: 20,
    },

    borderRadius: 12,
  },
  sortBox: {
    justifyContent: 'flex-end',
    textAlign: 'end',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      justifyContent: 'flex-start',
    },
  },
  sortPopper: {
    '& > div': {
      borderTopRightRadius: 0,
    },
    right: '10px !important',
    top: '100% !important',
    transform: 'unset !important',
    width: 282,
  },
  typePopper: {
    '& > div': {
      borderTopLeftRadius: 0,
    },
    boxShadow: '0px 5px 6px #0000001F',
    width: 220,
  },
}));

export default useStyles;
