import {getOffersFields} from '../utils/persistOfferDetails';

const validate = (fieldsKeyToValidate, values) => fieldsKeyToValidate
  .every(field => values[field] || values[field] === 0);

const homeInfoDetails = ['policyType', 'policyStartDate', 'policyEndDate'];
const insuranceDetails = [...homeInfoDetails, 'residenceType'];
const offersDetails = [...insuranceDetails, 'insuranceClaims'];

export default [
  {
    check: () => {
      const values = getOffersFields();
      return validate(homeInfoDetails, values);
    },
    redirectTo: '/getting-started',
    uri: '/details#home-info',
  },
  {
    check: () => {
      const values = getOffersFields();
      return validate(insuranceDetails, values);
    },
    redirectTo: '/details#home-info',
    uri: '/details#insurance-history',
  },
  {
    check: () => {
      const values = getOffersFields();
      return validate(offersDetails, values);
    },
    redirectTo: '/details#insurance-history',
    uri: '/offers',
  },
];
