import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import TabsLayout from '../../components/TabsLayout/TabsLayout';
import tabs from '../../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../../utils/routingHelpers';
import {getOffersFields} from '../../utils/persistOfferDetails';
import OtpContainer from '../../components/OtpContainer';
import {fireGtmEvent} from '../../utils/marketingUtils';
import InfoTab, {propertyDetailsValidationSchema} from './InfoTab';
import InsuranceHistoryStep, {insuranceHistoryValidationSchema} from './InsuranceHistoryTab';

const getInitialValues = () => ({
  residenceType: '',
  apartmentFloor: 0,
  buildingFloors: 4,
  squareMeters: '',
  buildingAge: '',
  city: '',
  cityCode: '',
  cityPostCode: '',
  residentsNumber: '',
  isMortgage: '',
  isBuiltFromBlocks: '',
  isOffice: '',
  insuranceClaims: '',
  insuranceClaimInYear: '',
  ...getOffersFields(),
});

const DetailsStep = ({history, location}) => {
  const [tab, setTab] = React.useState(0);
  const [isOtpOpen, setOtpOpen] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(getInitialValues());

  React.useEffect(() => {
    setInitialValues(getInitialValues());
    setTab(getCurrentTabIndex(location, 'details') || 0);
  }, [location]);

  const handleTabChange = (tabIndex) => {
    if (tabIndex === tab) {
      return;
    }
    setTab(tabIndex);
    const tabName = tabIndexToName(tabIndex, 'details');
    history.push(`#${tabName}`);
  };

  const handleOtpVerified = () => {
    history.push('/offers');
  };

  const handleOtpBlocked = () => {
    history.push('/overuse');
  };

  const handleOtpOpen = () => {
    if (getOffersFields('phone') && getOffersFields('token') && getOffersFields('offersCallId')) {
      handleOtpVerified();
    } else {
      setOtpOpen(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        ...propertyDetailsValidationSchema,
        ...insuranceHistoryValidationSchema,
      })}
    >
      {formikProps => (
        <Form>
          <TabsLayout
            tabs={tabs.details}
            currentTabIndex={tab}
            formikHandleSubmit={formikProps.handleSubmit}
            formikValidateFrom={formikProps.validateForm}
            formikHandleChange={formikProps.handleChange}
            formikSetField={formikProps.setFieldValue}
            formikSetTouched={formikProps.setFieldTouched}
            formikHandleBlur={formikProps.handleBlur}
            formikErrors={formikProps.errors}
            formikResetForm={formikProps.resetForm}
            formikValues={formikProps.values}
            formikIsSubmitting={formikProps.isSubmitting}
            formikTouched={formikProps.touched}
            isFormValid={formikProps.isValid}
            handleChange={(event, tabIndex) => {
              fireGtmEvent(`homeBackTo${tabs.details.find(t => t.index === tabIndex)?.gtmLabel}`);
              handleTabChange(tabIndex);
            }}
          >
            <InfoTab />
            <InsuranceHistoryStep handleOtpOpen={handleOtpOpen} />
          </TabsLayout>
          <OtpContainer
            open={isOtpOpen}
            handleVerified={handleOtpVerified}
            handleBlocked={handleOtpBlocked}
            handleClose={() => setOtpOpen(false)}
          />
        </Form>
      )}
    </Formik>
  );
};

DetailsStep.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(DetailsStep);
