import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core';
import {ContactUs} from 'wobi-web-common';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {getOffersFields} from '../utils/persistOfferDetails';
import {fireGtmEvent} from '../utils/marketingUtils';
import {CONTACT_US_SOURCES} from '../consts';

const SideBar = ({intl}) => {
  const classes = useStyles();
  const messages = id => intl.formatMessage({id});
  return (
    <div className={classes.sticky}>
      <ContactUs
        postRequest={(values) => {
          fireGtmEvent('homeContactUs');
          return handleContactUsSubmit({
            ...getOffersFields(),
            firstname: values.name?.split(' ')[0],
            lastname: values.name?.split(' ')[1],
            phone: values.phone,
          }, CONTACT_US_SOURCES.SIDEBAR);
        }}
        isOpen={false}
        label={messages('contact_us.terms_conditions_label')}
        linkText={messages('contact_us.terms_conditions_link_text')}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  sticky: {
    position: 'sticky',
    top: 30,
  },
}));

SideBar.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(SideBar);
