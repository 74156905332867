import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Dialog, Slide, Box, Grid, Typography,
  TextField, Icon, Button, CircularProgress} from '@material-ui/core';
import {Formik, Form, ErrorMessage} from 'formik';
import {injectIntl, FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {EzFormControl, Tooltip} from 'wobi-web-common';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import alertImage from '../../assets/images/alert.svg';
import peopleImage from '../../assets/images/people.png';
import cities from '../../config/cities';
import {setOffersFields, getOffersFields} from '../../utils/persistOfferDetails';
import {handleAdditionalDetailsSubmit, getStreetsByCityCode} from '../../utils/apiHandlers';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const Transition = React.forwardRef((props, ref) => <Slide direction='down' ref={ref} {...props} />);

const ShippingDetails = ({intl, onClose, open}) => {
  const messages = (id) => intl.formatMessage({id}) || '';
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [streets, setStreets] = React.useState([]);

  const {firstname, lastname, city, cityCode, cityPostCode} = JSON.parse(sessionStorage.getItem('offers_fields'));

  useEffect(() => {
    getStreetsByCityCode(cityCode).then((res) => {
      const foundStreets = res.data || [];
      setStreets(foundStreets);
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const validationMessage = {
    number: (<FormattedMessage id='validation.number_not_valid'/>),
    required: (<FormattedMessage id='validation.required'/>),
  };

  const validationSchema =
    yup.object().shape({
      deliveryCity: yup.string().required(validationMessage.required),
      deliveryHouseNum: yup.number().required(validationMessage.required).typeError(validationMessage.number),
      deliveryStreet: yup.string().required(validationMessage.required),
      email: yup.string().matches(/^[\w-.+]+@([\w-]+\.)+\w{2,4}$/, messages('validation.email'))
        .required(validationMessage.required),
      ownerFullName: yup.string().required(validationMessage.required),
    });

  const initialValues = {
    deliveryCity: city,
    deliveryCityCode: cityCode,
    deliveryCityPostCode: cityPostCode,
    deliveryHouseNum: '',
    deliveryStreet: '',
    email: '',
    ownerFullName: firstname && lastname ? `${firstname || ''} ${lastname || ''}` : '',
  };

  const handleCitySelectChange = (value) => cities.filter(item => item.name.slice(0, value.length) === value)
    .map(item => Object.assign(item, {
      label: item.name,
      value: item.code,
    }));

  const handleCityChange = async (cityObject, setFieldValue) => {
    if (!cityObject || !cityObject.name) {
      setFieldValue('deliveryCity', '');
      setFieldValue('deliveryCityCode', '');
      setFieldValue('deliveryCityPostCode', 0);
      setFieldValue('deliveryStreet', '');
      setStreets([]);
    } else {
      setFieldValue('deliveryCity', cityObject.name);
      setFieldValue('deliveryCityCode', cityObject.code);
      setFieldValue('deliveryCityPostCode', Number(cityObject.post_code));

      const responseData = await getStreetsByCityCode(cityObject.code);
      const foundStreets = responseData.data || [];
      setStreets(foundStreets);
    }
  };

  const handleSetStreets = (value) => streets.filter(item => item.name.slice(0, value.length) === value)
    .map(item => Object.assign(item, {
      label: item.name,
      value: item.name,
    }));

  const handleStreetChange = (streetObject, setFieldValue) => {
    if (!streetObject || !streetObject.name || !streetObject.city_id) {
      setFieldValue('deliveryStreet', '');
    } else {
      setFieldValue('deliveryStreet', streetObject.name);
    }
  };

  const assembleAdditionalData = (formValues) => ({
    apartmentNum: '',
    city: getOffersFields('city'),
    cityCode: getOffersFields('cityCode'),
    cityPostCode: getOffersFields('cityPostCode'),
    deliveryApartmentNum: '',
    deliveryMailbox: '',
    hasDifferentAddress: '',
    houseNum: '',
    ownerFirstName: getOffersFields('firstname'),
    ownerIdentificationNumber: '',
    ownerLastName: getOffersFields('lastname'),
    ownerPhone: getOffersFields('phone'),
    potentialId: getOffersFields('potentialId'),
    street: '',
    token: getOffersFields('offersCallId'),
    ...formValues,
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const requestData = {
      ...values,
      firstName: values.ownerFullName ? values.ownerFullName.split(' ')[0] : null,
      lastName: values.ownerFullName ? values.ownerFullName.split(' ')[1] : null,
    };
    setOffersFields(requestData);
    fireGtmEvent('homeShippingDetails');
    try {
      await handleAdditionalDetailsSubmit(assembleAdditionalData(requestData));
      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-labelledby='shipping_details-success-title'
      className={classes.modal}
      maxWidth='lg'
      open={open}
    >
      <Box px={{lg: 7, xs: 3}} py={3}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
        >
          {({values, errors, touched, handleChange, handleBlur, setFieldValue}) => (
            <Form>
              <Box mb={2} display='flex' alignItems='center'>
                <Box display={{lg: 'inline', xs: 'none'}}>
                  <img className={classes.successImage} src={peopleImage} alt='success'/>
                </Box>
                <Typography id='shipping_details-success-title' component='h1' className={classes.dialogTitle}>
                  <FormattedMessage id='shipping_details.success'/>
                </Typography>
              </Box>
              <Box mb={3}>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography component='h3' className={classes.dialogSubtitle}>
                      <FormattedMessage id='shipping_details.form_title'/>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip placement='right' title={<FormattedMessage id='shipping_details.tooltip_title'/>}>
                      <Icon className={classes.helpIcon}>help</Icon>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container spacing={1}>
                  <Grid item container spacing={3} xs={12} lg={10}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='ownerFullName-textfield'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.ownerFullName && touched.ownerFullName}
                        className={classes.fullWith}
                        name='ownerFullName'
                        value={values.ownerFullName}
                        label={<FormattedMessage id='shipping_details.field_full_name'/>}
                        helperText={<ErrorMessage name='ownerFullName'/>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='email-textfield'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        className={classes.fullWith}
                        name='email'
                        value={values.email}
                        label={<FormattedMessage id='shipping_details.field_email'/>}
                        helperText={<ErrorMessage name='email'/>}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3} xs={12} lg={12}>
                    <Grid item xs={12} sm={5}>
                      {/* provide select */}
                      <EzFormControl name='deliveryStreet'>
                        <AutocompleteSingleSelect
                          inputLabel={messages('fields.street')}
                          id='deliveryStreet-textfield'
                          onInput={handleSetStreets}
                          onSelect={(streetObject) => handleStreetChange(streetObject, setFieldValue)}
                        />
                      </EzFormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id='deliveryHouseNum-textfield'
                        error={errors.deliveryHouseNum && touched.deliveryHouseNum}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\s/g, '');
                          setFieldValue('deliveryHouseNum', value);
                        } }
                        onBlur={handleBlur}
                        className={classes.fullWith}
                        name='deliveryHouseNum'
                        value={values.deliveryHouseNum}
                        label={<FormattedMessage id='shipping_details.building_number'/>}
                        helperText={<ErrorMessage name='deliveryHouseNum'/>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <EzFormControl name='deliveryCity'>
                        <AutocompleteSingleSelect
                          inputLabel={messages('fields.city')}
                          onInput={handleCitySelectChange}
                          onSelect={(cityObject) => handleCityChange(cityObject, setFieldValue)}
                          defaultValue={{
                            cityCode: values.deliveryCityCode,
                            cityPostCode: values.deliveryCityPostCode,
                            label: values.deliveryCity,
                          }}
                        />
                      </EzFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container alignItems='center'>
                  <Grid item xs={2} md='auto'>
                    <Box pr={2}>
                      <img className={classes.alertImage} src={alertImage} alt='alert'/>
                    </Box>
                  </Grid>
                  <Grid item xs={10} md='auto'>
                    <span className={classes.alertText}><FormattedMessage id='shipping_details.alert_title'/></span>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Button
                  type='submit'
                  className={classes.submitButton}
                  disabled={isLoading}
                  data-testid='shipping-details-submit'
                >
                  {isLoading ?
                    <CircularProgress/> :
                    <>
                      <FormattedMessage id='shipping_details.send_button'/>
                      <Icon className={classes.iconBack}>call_received</Icon>
                    </>
                  }
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

ShippingDetails.propTypes = {
  intl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,

};

export default injectIntl(ShippingDetails);

