import {apiGetRequest, apiPostRequest} from './apiHelpers';

export const getStartingDate = () => apiGetRequest('api/get_start_date');

export const handleContactUsSubmit = (values, triggeredBy) => apiPostRequest('api/contact_us', {
  ...values,
  triggeredBy,
});

export const getToken = (details) => apiPostRequest('sms/get_token', details);

export const validateSecret = (request) => apiPostRequest('sms/validate_secret', request);

export const checkOfferStatus = (values) => apiPostRequest('api/check_status', values);

export const getCoversTitles = (policyType) => apiGetRequest(`data/get_covers_titles?policyType=${policyType}`);

export const getAllOffers = (requestData) => apiPostRequest('api/get_offers', requestData);

export const verifyPayment = (data) => apiPostRequest('api/verifyPayment', data);

export const updateCustomerSelectedOffer = (data) => apiPostRequest('api/updateCustomerSelectedOffer', data);

export const handleAdditionalDetailsSubmit = (values) => apiPostRequest('api/updateCustomerAdditionalDetails', values);

export const getStreetsByCityCode = (cityCode) => apiGetRequest(`data/streets/${cityCode}`);
