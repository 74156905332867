import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 50,
  },
  msg: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(14.9),
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: 20,
  },
  infoIcon: {
    color: theme.palette.text.secondary,
  },
  marginBottom: {
    marginBottom: 20,
  },
  yesNoGroup: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '& legend': {
      width: 270,
    },
    '& .MuiFormControl-fullWidth': {
      width: 'auto',
      '& >:first-child': {
        flexWrap: 'wrap',
      },
      '& fieldset': {
        marginBottom: 10,
      },
    },
  },
  materialsTooltip: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    },
  },
}));

export default useStyles;
