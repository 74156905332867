import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {makeStyles} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {Header as BaseHeader, Breadcrumbs, Breakpoints} from 'wobi-web-common';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {getOffersFields} from '../utils/persistOfferDetails';
import {GettingStartedRoute} from '../config/routes';
import fullDataSteps from '../config/steps';
import {areBreadCrumbsNeeded, getStepName, getTabsPercentage, stepNameToIndex} from '../utils/routingHelpers';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {dateDotFormat, dateSlashFormat} from '../utils/dateTimeHelpers';
import {fireGtmEvent} from '../utils/marketingUtils';
import {CONTACT_US_SOURCES} from '../consts';
import MainTitle from './MainTitle';

const useStyles = makeStyles(() => ({
  container: {
    '& .breadcrumbs': {
      maxWidth: 500,
    },
    '& .MuiPaper-root': {
      padding: 0,
    },

    padding: '10px 0 20px 0',
    [`@media screen and (max-width: ${Breakpoints.mobile}px)`]: {
      maxWidth: 'unset',
      padding: 0,
    },
  },
  wrapper: {
    [`@media screen and (max-width: ${Breakpoints.mobile}px)`]: {
      padding: '0 15px',
    },
  },
}));

const steps = fullDataSteps.map(step => step.label);

const isPolicyPeriodPresented = (historyProperty) => historyProperty &&
  historyProperty.location &&
  historyProperty.location.pathname !== GettingStartedRoute.pathname;

const Header = ({intl, history, location, hasContactUs, showOnlyBaseHeader, polType}) => {
  const [type, setType] = React.useState('');
  const [showSteps, setShowSteps] = React.useState(true);
  const [stepIndex, setStepIndex] = React.useState(0);
  const [percentage, setPercentage] = React.useState(0);
  const [isHeaderFixed, setHeaderFixed] = React.useState(location.pathname !== '/offers');
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${Breakpoints.mobile}px)`);

  const handleBack = () => {
    fireGtmEvent('homePreviousPage');
    history.goBack();
  };

  const setStepAndPercentage = (loc) => {
    const step = getStepName(loc);

    // Hide breadcrumbs on specific pages
    const hideBreadCrumbs = !areBreadCrumbsNeeded(step);
    if (hideBreadCrumbs) {
      setShowSteps(false);
      return;
    }

    const stepIn = stepNameToIndex(step);
    const percent = getTabsPercentage(loc, 'details');
    setPercentage(percent);
    setShowSteps(true);
    setStepIndex(stepIn);
  };

  const handleClickOutside = () => {
    setTimeout(() => {
      setType(getOffersFields('policyType'));
    });
  };

  React.useEffect(() => {
    if (!polType) {
      setType(getOffersFields('policyType'));
    }
    setStepAndPercentage(location);
    const unlisten = history.listen((loc) => {
      setStepAndPercentage(loc);
    });
    document.addEventListener('mouseup', handleClickOutside, false);
    return () => {
      unlisten();
      document.removeEventListener('mouseup', handleClickOutside, false);
    };
  }, []);

  React.useEffect(() => {
    if (polType) {
      setType(polType);
    }
  }, [polType]);

  React.useEffect(() => {
    setHeaderFixed(location.pathname !== '/offers');
  }, [location.pathname]);

  const getPolicyTypeTitle = () => (
    <React.Fragment>
      ביטוח דירה -
      <span>
        {type && intl.formatMessage({id: type.toLowerCase()})}
      </span>
    </React.Fragment>
  );

  const handleBreadcrumbsClick = index => {
    const wantedStep = fullDataSteps[index];
    if (stepIndex !== index && wantedStep.guard()) {
      setStepIndex(index);
      history.push(`/${wantedStep.key}`);
    }
  };

  return (
    <div className={classes.container}>
      <BaseHeader
        callHandler={() => fireGtmEvent('homeCall')}
        backFunction={handleBack}
        logoHandler={() => {
          fireGtmEvent('homeBackToHomePage');
        }}
        headerContactHandler={() => fireGtmEvent('HomeHeaderContact')}
        isHeaderFixed={isHeaderFixed}
        hasChatButton
        chatHandler={() => fireGtmEvent(isMobile ? 'Home2Chat' : 'Home2ChatDSK')}
        handler={values => {
          if (!isMobile) {
            fireGtmEvent('homeContactUs');
          }
          return handleContactUsSubmit({
            ...values,
            fullName: values.fullname,
            lastname: values.fullname?.split(' ')[0],
            firstname: values.fullname?.split(' ')[1],
            platform: isMobile ? 'Mobile' : 'Desktop',
            ...getOffersFields(),
          }, isMobile ? CONTACT_US_SOURCES.HEADER_MOBILE : CONTACT_US_SOURCES.HEADER);
        }}
        hasContactus={hasContactUs}
        onCallMeClick={() => {
          const offersFields = getOffersFields();
          const {firstname, lastname, phone} = offersFields || {};
          if (firstname && lastname && phone) {
            return {
              firstname,
              lastname,
              phone,
            };
          }
          return null;
        }}
      />
      {showOnlyBaseHeader ? false : (
        <div className={classes.wrapper}>
          <MainTitle
            mainText={<React.Fragment>
              {getPolicyTypeTitle()}
            </React.Fragment>}
            subText={isPolicyPeriodPresented(history) &&
              getOffersFields('policyStartDate') &&
              getOffersFields('policyEndDate') ? <React.Fragment>
                תקופת ביטוח
                <span>
                  {Moment(getOffersFields('policyStartDate'), dateSlashFormat).format(dateDotFormat)}
                  -
                  {Moment(getOffersFields('policyEndDate'), dateSlashFormat).format(dateDotFormat)}
                </span>
              </React.Fragment> : ''}
          />
          {showSteps && (
            <Breadcrumbs
              currentStep={stepIndex}
              subStepPercentage={percentage}
              steps={steps}
              handleClick={handleBreadcrumbsClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  hasContactUs: PropTypes.bool,
  history: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
  showOnlyBaseHeader: PropTypes.bool,
  polType: PropTypes.string,
};

export default withRouter(injectIntl(Header));
