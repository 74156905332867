/* eslint-disable max-len */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const iconsColors = makeStyles(theme => ({
  cls1: {
    fill: theme.palette.primary.main,
  },
  cls2: {
    fill: theme.palette.secondary.mainDark,
  },
  cls3: {
    fill: 'none',
    stroke: theme.palette.primary.main,
  },
  cls4: {
    fill: 'none',
    stroke: theme.palette.secondary.mainLight,
  },
}));

const InfoIcon = ({...props}) => {
  const classes = iconsColors();
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
      <path className={classes.cls2} d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
    </svg>
  );
};

export {InfoIcon};
