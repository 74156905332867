const routeBuilder = (pathname, showSidebar, showHeader, showOnlyBaseHeader = false) => ({
  pathname,
  showHeader,
  showOnlyBaseHeader,
  showSidebar,
});

export const GettingStartedRoute = routeBuilder('/getting-started', true, true);
export const DetailsRoute = routeBuilder('/details', true, true);
export const OffersRoute = routeBuilder('/offers', false, true);
export const ThankYouRoute = routeBuilder('/thank-you', false, true);
export const OverUseRoute = routeBuilder('/overuse', false, true, true);

export const routes = [
  GettingStartedRoute,
  DetailsRoute,
  OffersRoute,
  ThankYouRoute,
  OverUseRoute,
];
