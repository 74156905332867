import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {RadioGroup, Radio, FormControlLabel, Button, Grow, Paper} from '@material-ui/core';
import {EzFormControl, DatePicker, AlwaysOnTooltip} from 'wobi-web-common';
import {FormattedMessage, injectIntl} from 'react-intl';
import 'moment/locale/he';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {setOffersFields, getOffersFields} from '../../utils/persistOfferDetails';
import {conditions} from '../../utils/formConditions';
import HomeImg from '../../assets/images/hero-house.png';
import {getStartingDate} from '../../utils/apiHandlers';
import {
  currentDateHyphen, dateSlashFormat,
  getDayFromDate,
  getDaysInMonth,
  getDaysSinceMonthStart,
  getDaysToMonthEnd, getPolicyEndDate,
  nextMonth, nextMonthHyphen,
  nextMonthsEnd,
  reformatSlashDateToHyphen, todayHyphen,
} from '../../utils/dateTimeHelpers';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const nextMonthTitle = `מ - 1 ${nextMonth.format('MMMM')}`;

const getStartDateFromStorage = () => {
  const policyStartDate = getOffersFields('policyStartDate');
  if (policyStartDate) {
    return reformatSlashDateToHyphen(policyStartDate);
  }
  return '';
};

const getTooltipMessage = (date) => {
  const diff = getDaysSinceMonthStart(date);
  if (diff) {
    const diffFromEnd = getDaysToMonthEnd(date);

    // 1 day should be added in order to get correct count of all days including entire last day
    return `לתקופה של 11 חודשים ו-${diffFromEnd + 1} ימים`;
  } else {
    return 'תקופה של 12 חודשים';
  }
};

const isPolicyStartDayIsLast2DaysOfMonth = (policyStartDate) => getDaysInMonth(policyStartDate) -
  getDayFromDate(policyStartDate) < 2;

const GettingStarted = ({history, intl}) => {
  const [initialStartDate, setInitialStartDate] = React.useState('');
  const [policyStartDate, setPolicyStartDate] = React.useState(getStartDateFromStorage());
  const classes = useStyles();

  const getStartDate = async () => {
    const resp = await getStartingDate();
    if (resp.success) {
      setInitialStartDate(resp.data.startDate);
    }
  };

  React.useEffect(() => {
    getStartDate();
  }, []);

  const selectStartDate = () => {
    if (initialStartDate === currentDateHyphen) {
      return policyStartDate || initialStartDate;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <div className='form-container'>
        <div className={classes.title} data-testid='getting-started-title'>
          <FormattedMessage id='getting_started.here_we_begin' tagName='h1'/>
          <FormattedMessage id='getting_started.what_date' tagName='span'/>
        </div>
        {
          initialStartDate ?
            <Grow in={Boolean(initialStartDate)}>
              <Paper className={classes.paper}>
                <Formik
                  onSubmit={(values) => {
                    const policyEndDate = getPolicyEndDate(values.policyStartDate);
                    const data = {
                      policyEndDate,
                      policyStartDate: Moment(values.policyStartDate).format(dateSlashFormat),
                    };
                    setOffersFields(data);
                    fireGtmEvent('homeConfirmDate', data);
                    history.push('details#home-info');
                  }}
                  validationSchema={yup.object().shape({
                    policyStartDate: yup.mixed().required('שדה חובה')
                      .test('validDate', 'תאריך שגוי', (date) => conditions.isValidDate(date))
                      .test('underMax', 'לא ניתן לרכוש פוליסה שתתחיל לאחר סוף החודש הבא',
                        (date) => conditions.isUnderMax(date, nextMonthsEnd))
                      .test('aboveMin', 'לא ניתן לרכוש פוליסה שמתחילה בתאריך זה',
                        (date) => conditions.isAboveMin(date, Moment())),
                  })}
                  initialValues={{policyStartDate: selectStartDate()}}>
                  {({values, errors, setFieldValue}) => (
                    <Form>
                      <EzFormControl name='predefinedDates'>
                        <RadioGroup
                          className={classes.preDefineDates}
                          onChange={(event, value) => setFieldValue('policyStartDate', value)}
                          row
                        >
                          <FormControlLabel
                            checked={(policyStartDate || initialStartDate) === todayHyphen}
                            disabled={initialStartDate !== todayHyphen}
                            value={initialStartDate}
                            onChange={() => {
                              fireGtmEvent('homeToday');
                              setPolicyStartDate(initialStartDate);
                            }}
                            control={<Radio inputProps={{'aria-setsize': '2'}}/>}
                            data-testid='start-date-today'
                            label={<FormattedMessage id='getting_started.today'/>}
                          />
                          <FormControlLabel
                            checked={nextMonthHyphen === values.policyStartDate}
                            value={nextMonthHyphen}
                            control={<Radio inputProps={{'aria-setsize': '2'}}/>}
                            onChange={() => {
                              fireGtmEvent('homeFirstDate');
                              setPolicyStartDate(nextMonthHyphen);
                            }}
                            data-testid='start-date-next-month'
                            label={nextMonthTitle}
                          />
                        </RadioGroup>
                      </EzFormControl>
                      <div className={classes.wrapper}>
                        <EzFormControl
                          label={intl.formatMessage({id: 'getting_started.different_date'})}
                          name='policyStartDate'
                          isLabelInChild
                        >
                          <DatePicker
                            style={{width: '100%'}}
                            value={values.policyStartDate}
                            onChange={(date) => {
                              if (date && Moment(date).isValid()) {
                                fireGtmEvent('homeOtherDate', {date});
                              }
                              setFieldValue('policyStartDate', date);
                            }}
                            onAccept={() => {}}
                            autoOk
                            invalidDateMessage=''
                            minDateMessage=''
                            maxDateMessage=''
                            minDate={initialStartDate}
                            maxDate={nextMonthsEnd}
                            openTo='date'
                            id='differentPolicyStartDate'
                            variant='inline'
                          />
                        </EzFormControl>
                        <AlwaysOnTooltip
                          show={Boolean(values.policyStartDate)}
                          kind='Bulb'
                          showText
                          className={classes.tooltipOverride}
                        >
                          {getTooltipMessage(values.policyStartDate)}
                        </AlwaysOnTooltip>
                        {
                          isPolicyStartDayIsLast2DaysOfMonth(values.policyStartDate) && (
                            <div className={classes.text}>
                              <FormattedMessage id='getting_started.last_day_of_month_warning'/>
                            </div>
                          )
                        }
                        <div>
                          <Button
                            className={classes.submitBtn}
                            size='large'
                            type='submit'
                            data-testid='getting-started-submit'
                            aria-label={errors && Object.keys(errors).length ?
                              `${intl.formatMessage({id: 'getting_started.validation_submit_ariaLabel'})}. ` : null}>
                            <FormattedMessage id='getting_started.continue_to_home_details'/>
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grow> : false
        }
      </div>
      <div className='img-container'>
        <img src={HomeImg} alt='insurance date'/>
      </div>
    </div>
  );
};

GettingStarted.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(GettingStarted);
