// insurance companies logos
import ClalLogo_big from '../images/company-logos/150x84/ClalLogo.png';
import MenoraLogo_big from '../images/company-logos/150x84/MenoraLogo.png';
import ShirbitLogo_big from '../images/company-logos/150x84/ShirbitLogo.png';
import HarelLogo_big from '../images/company-logos/150x84/HarelLogo.png';
import ShlomoLogo_big from '../images/company-logos/150x84/ShlomoLogo.png';
import ClalLogo_small from '../images/company-logos/88x47/ClalLogo.png';
import MenoraLogo_small from '../images/company-logos/88x47/MenoraLogo.png';
import ShirbitLogo_small from '../images/company-logos/88x47/ShirbitLogo.png';
import HarelLogo_small from '../images/company-logos/88x47/HarelLogo.png';
import ShlomoLogo_small from '../images/company-logos/88x47/ShlomoLogo.png';

const companiesLogos = [
  {imgSrc_big: ClalLogo_big, imgSrc_small: ClalLogo_small, key: 'ClalLogo'},
  {imgSrc_big: MenoraLogo_big, imgSrc_small: MenoraLogo_small, key: 'MenoraLogo'},
  {imgSrc_big: ShirbitLogo_big, imgSrc_small: ShirbitLogo_small, key: 'ShirbitLogo'},
  {imgSrc_big: ShlomoLogo_big, imgSrc_small: ShlomoLogo_small, key: 'ShlomoLogo'},
  {imgSrc_big: HarelLogo_big, imgSrc_small: HarelLogo_small, key: 'HarelLogo'},

];

export default companiesLogos;
