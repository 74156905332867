import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Grid, Box, Button, CircularProgress} from '@material-ui/core';
import {getOffersFields} from '../../utils/persistOfferDetails';
import LaptopImage from '../../assets/images/hero-home.png';
import {handleContactUsSubmit} from '../../utils/apiHandlers';
import Coffee from '../../assets/images/coffee.svg';
import {CONTACT_US_SOURCES} from '../../consts';
import useStyles from './useStyles';

const OverUse = () => {
  const classes = useStyles();
  const [isContactingUs, setContactingUs] = React.useState(false);
  const [isThankYou, setThankYou] = React.useState(false);
  const policyDetails = {
    firstname: getOffersFields('firstname'),
    lastname: getOffersFields('lastname'),
    phone: getOffersFields('phone'),
  };

  const submitAction = async () => {
    setContactingUs(true);

    await handleContactUsSubmit({
      ...getOffersFields(),
      fullname: `${policyDetails.firstname} ${policyDetails.lastname}`,
      phone: policyDetails.phone,
    }, CONTACT_US_SOURCES.OVERUSE);
    setContactingUs(false);
    setThankYou(true);
    setTimeout(() => {
      setThankYou(false);
    }, 5000);
  };

  return (
    <div>
      <Box pr={{lg: 0, xs: 3}}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <div className={classes.messageTop}>
              <FormattedMessage id='overuse.main_message' />
            </div>
          </Grid>
          {isThankYou ? (
            <Grid item xs={12} lg={8}>
              <div className={classes.contactUsThanks}>
                <img src={Coffee} className='coffee' alt='coffee'/>
                <div className={classes.contactUsThanksText}>
                  <div>פנייתך התקבלה</div>
                  <div>נציג מכירות יחזור אליך בהקדם</div>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} lg={8}>
              {isContactingUs ? (
                <CircularProgress className='contact_us_loader' size={40}/>
              ) : (
                <Button
                  className={classes.callMeBackButton}
                  variant='outlined'
                  onClick={submitAction}
                >
                  <FormattedMessage id='overuse.send_button' />
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      <div className={classes.imgContainer}>
        <img src={LaptopImage} alt='contact us for better offers'/>
      </div>
    </div>
  );
};

export default OverUse;
