export function throttle(callback, limit) {
  let waiting = false;
  return function () {
    if (!waiting) {
      // eslint-disable-next-line
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
}
