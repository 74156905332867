import stepsTabs from '../config/tabs';
import steps from '../config/steps';

export const getStepName = (location) => {
  if (location && location.pathname) {
    const {pathname} = location;
    return pathname.replace('/', '');
  }
  return '';
};

export const areBreadCrumbsNeeded = (step) => Boolean(steps.find(item => item.key === step));

export const stepNameToIndex = (name) => {
  const stepObject = steps.find(item => item.key === name);
  return stepObject ? stepObject.index : null;
};

export const getCurrentTabIndex = (location, step) => {
  const tabs = stepsTabs[step];
  if (!tabs) {
    return false;
  }

  const {hash} = location;
  const tabKey = hash.replace('#', '');
  const tabObject = tabs.find(tab => tab.key === tabKey);
  return tabObject ? tabObject.index : false;
};

export const getTabsPercentage = (location, step) => {
  const tabs = stepsTabs[step];
  const tabIndex = getCurrentTabIndex(location, step);
  return tabIndex === false ? 0 : tabIndex / tabs.length * 100;
};

export const tabIndexToName = (tabIndex, step) => {
  const tabs = stepsTabs[step];
  if (!tabs) {
    return false;
  }
  const tabObject = tabs.find(tab => tab.index === tabIndex);
  return tabObject ? tabObject.key : false;
};
