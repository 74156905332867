import {makeStyles} from '@material-ui/core';

export const CUSTOM_MAX_WIDTH = 408;

export default makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.text.secondary,
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginTop: {
    marginTop: 20,
  },
  customWidth: {
    maxWidth: CUSTOM_MAX_WIDTH,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  yesNoGroup: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '& legend': {
      marginBottom: 5,
      width: 270,
    },
    '& .MuiFormControl-fullWidth': {
      width: 'auto',
      '& >:first-child': {
        flexWrap: 'wrap',
      },
      '& fieldset': {
        marginBottom: 10,
      },
    },
  },
  materialsTooltip: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    },
  },
  materialsTooltipBubble: {
    maxWidth: 230,
  },
  error: {
    marginBottom: 5,
  },
  radioGroupLegend: {
    marginBottom: 5,
  },
}));
