import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import {IntlProvider} from 'react-intl';
import {WobiThemeProvider} from 'wobi-web-common';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rollbar from './config/rollbar';
import messages_he from './messages/he.json';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';

window.rollbar = rollbar;

const gtmEnvironment = {
  gtmId: agentDeveloperUtils.getGtmId(),
};

TagManager.initialize(gtmEnvironment);

const defaultLanguage = 'he';
const messages = {
  he: messages_he,
};
const AdditionalGtmIdArr = agentDeveloperUtils.getAdditionalTagManegerIds();
if (AdditionalGtmIdArr && Array.isArray(AdditionalGtmIdArr) && AdditionalGtmIdArr.length > 0) {
  // eslint-disable-next-line no-unused-vars
  for (const additionalGtmId of AdditionalGtmIdArr) {
    const additionalGtmEnviroment = {
      gtmId: additionalGtmId,
    };
    TagManager.initialize(additionalGtmEnviroment);
  }
}
ReactDOM.render(
  <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
    <WobiThemeProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </WobiThemeProvider>
  </IntlProvider>, document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
