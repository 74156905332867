import React from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {FormattedMessage, injectIntl} from 'react-intl';
import {InputAdornment, TextField, Button, Select, MenuItem} from '@material-ui/core';
import {AlwaysOnTooltip, EzFormControl, Tooltip, YesNoRadioGroup} from 'wobi-web-common';
import HelpIcon from '@material-ui/icons/InfoOutlined';
import productTypes from '../../consts/product-type';
import {setOffersFields} from '../../utils/persistOfferDetails';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const updateOffersFields = (values, newPolicyType) => {
  if (values.isBuiltFromBlocks === 'no' || values.isOffice === 'yes') {
    return true;
  }

  setOffersFields({...values, policyType: newPolicyType});
  return false;
};

const ContentMissingFieldsForm = ({intl, getOffers, newPolicyType}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Formik
        initialValues={{squareMeters: '', isMortgage: '', isBuiltFromBlocks: '', isOffice: ''}}
        validationSchema={yup.object().shape({
          squareMeters: yup.string().required('שדה חובה'),
          isMortgage: yup.string().required('שדה חובה'),
          isBuiltFromBlocks: yup.string().required('שדה חובה'),
          isOffice: yup.string().required('שדה חובה'),
        })}
        onSubmit={(values) => {
          const isBlocked = updateOffersFields(values, newPolicyType);
          if (isBlocked) {
            return;
          }
          getOffers();
        }}
      >
        {({values, handleChange, isValid}) => (
          <Form>
            <div className={classes.msg}><FormattedMessage id='offers.in_order_to_change_content'/></div>
            <TextField
              fullWidth
              variant='standard'
              value={values.squareMeters}
              name='squareMeters'
              onChange={handleChange}
              label={intl.formatMessage({id: 'details.apartment_area'})}
              className={classes.marginBottom}
              InputProps={{
                endAdornment: <InputAdornment position='end' className={classes.infoIcon}>
                  <Tooltip placement='top' title={intl.formatMessage({id: 'details.apartment_area_help'})}>
                    <HelpIcon/>
                  </Tooltip>
                </InputAdornment>,
              }}
            />
            <div className={classes.yesNoGroup}>
              <EzFormControl name='isMortgage' label={intl.formatMessage({id: 'details.is_mortgage'})} isLabelInChild>
                <YesNoRadioGroup
                  value={values.isMortgage}
                  onChange={handleChange}
                />
              </EzFormControl>
            </div>
            <div className={classes.yesNoGroup}>
              <EzFormControl
                name='isBuiltFromBlocks'
                label={intl.formatMessage({id: 'details.building_materials'})}
                isLabelInChild
              >
                <YesNoRadioGroup
                  value={values.isBuiltFromBlocks}
                  onChange={handleChange}
                />
                <AlwaysOnTooltip
                  show={values.isBuiltFromBlocks === 'no'}
                  kind='Alert'
                  showText
                  className={classes.materialsTooltip}
                >
                  <FormattedMessage id='details.building_materials_warning' />
                </AlwaysOnTooltip>
              </EzFormControl>
            </div>
            <div className={classes.yesNoGroup}>
              <EzFormControl name='isOffice' label={intl.formatMessage({id: 'details.is_office'})} isLabelInChild>
                <YesNoRadioGroup
                  value={values.isOffice}
                  onChange={handleChange}
                />
                <AlwaysOnTooltip
                  show={values.isOffice === 'yes'}
                  kind='Alert'
                  showText
                  className={classes.materialsTooltip}
                >
                  <FormattedMessage id='details.is_office_warning' />
                </AlwaysOnTooltip>
              </EzFormControl>
            </div>
            <Button
              type='submit'
              disabled={!isValid}
              onClick={() => fireGtmEvent('homePolicyChange', {...values})}>
              {intl.formatMessage({id: 'offers.get_offers'})}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ContentMissingFieldsForm.propTypes = {
  getOffers: PropTypes.func,
  intl: PropTypes.object,
  newPolicyType: PropTypes.string,
};

const StructureMissingFieldsForm = ({intl, getOffers, newPolicyType}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Formik
        initialValues={{residentsNumber: ''}}
        validationSchema={yup.object().shape({
          residentsNumber: yup.string().required('שדה חובה'),
        })}
        onSubmit={(values) => {
          const isBlocked = updateOffersFields(values, newPolicyType);
          if (isBlocked) {
            return;
          }
          getOffers();
        }}
      >
        {({values, handleChange}) => (
          <Form>
            <div className={classes.msg}><FormattedMessage id='offers.in_order_to_change_structure'/></div>
            <div>
              <EzFormControl
                name='residentsNumber'
                label={intl.formatMessage({id: 'details.residents_number'})}
                labelFor='select-residents-number'
              >
                <Select
                  id='select-residents-number'
                  labelId='year-label'
                  fullWidth
                  value={values.residentsNumber}
                  onChange={handleChange}
                >
                  {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'].map(num => (
                    <MenuItem key={`num-${num}`} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </EzFormControl>
            </div>
            <Button
              type='submit'
              onClick={() => fireGtmEvent('homePolicyChange', {residentsNumber: values.residentsNumber})}>
              {intl.formatMessage({id: 'offers.get_offers'})}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

StructureMissingFieldsForm.propTypes = {
  getOffers: PropTypes.func,
  intl: PropTypes.object,
  newPolicyType: PropTypes.string,
};

const MissingFieldsForm = ({oldPolicyType, intl, getOffers, newPolicyType}) => {
  if (oldPolicyType === productTypes.Content) {
    return (
      <ContentMissingFieldsForm intl={intl} getOffers={getOffers} newPolicyType={newPolicyType} />
    );
  }
  return <StructureMissingFieldsForm getOffers={getOffers} intl={intl} newPolicyType={newPolicyType} />;
};

MissingFieldsForm.propTypes = {
  getOffers: PropTypes.func,
  intl: PropTypes.object,
  oldPolicyType: PropTypes.string,
  newPolicyType: PropTypes.string,
};

export default injectIntl(MissingFieldsForm);
