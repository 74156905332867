import Moment from 'moment';

const yesNoToBool = value => {
  if (value === 'yes') {
    return true;
  } else if (value === 'no') {
    return false;
  }
  return value;
};

export const mapRequestData = (oldData) => {
  const output = {...oldData};
  Object.entries(oldData).forEach(([key, value]) => {
    output[key] = yesNoToBool(value);
  });
  return output;
};

export const utils = {
  getMonthsOptions: () => Array.from({length: 12}, (v, k) => k + 1),

  getYearsOptions: () => {
    const year = Moment().year();
    return Array.from({length: 10}, (v, k) => year + k);
  },

  licenseNumberValidation: value => {
    if (!value) {
      return true;
    }
    const reg = new RegExp(/^\d+$/);
    return (value.toString().length === 7 || value.toString().length === 8) && reg.test(value);
  },
};
