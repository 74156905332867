import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlwaysOnTooltip, CallMePopover} from 'wobi-web-common';
import {ErrorMessage} from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core';
import {getOffersFields, setOffersFields} from 'utils/persistOfferDetails';
import {fireGtmEvent} from 'utils/marketingUtils';
import {handleContactUsSubmit} from 'utils/apiHandlers';
import {CONTACT_US_SOURCES} from 'consts';
import useStyles from './useStyles';

const InsuranceHistoryStep = ({
  formikValues,
  formikHandleChange,
  formikValidateFrom,
  formikSetTouched,
  formikErrors,
  className,
  handleOtpOpen,
}) => {
  const classes = useStyles();
  const [callMeOpen, setCallMeOpen] = useState(false);
  const [callMeSubmitted, setCallMeSubmitted] = useState(false);
  const intl = useIntl();
  const submitButtonAriaLabel = () => {
    if (formikValues.insuranceClaims === '2') {
      return intl.formatMessage({id: 'insurance_history.2_and_more_claims_tooltip'});
    } else if (formikErrors && Object.keys(formikErrors).length) {
      return intl.formatMessage({id: 'insurance_history.validation_submit_ariaLabel'});
    } else {
      return null;
    }
  };

  useEffect(() => {
    setCallMeSubmitted(false);

    // setCallMeSubmitSuccess(false);
    if (formikValues.insuranceClaims === '1') {
      setCallMeOpen(true);
    }
  }, [formikValues]);

  const handleCallMeClose = () => {
    setCallMeOpen(false);
  };

  const callMeid = callMeOpen ? 'call-me-popover' : undefined;

  const handleCallMeSubmit = async (values) => {
    fireGtmEvent('OneClaimContactUs', getOffersFields());
    const result = await handleContactUsSubmit({
      ...getOffersFields(),
      firstname: values.name?.split(' ')[0],
      lastname: values.name?.split(' ')[1],
      phone: values.phone,
    }, CONTACT_US_SOURCES.ONE_OR_MORE_CLAIMS);
    setCallMeSubmitted(true);
    if (result.success) {
      setTimeout(() => setCallMeOpen(false), 2000);
    }
  };

  const handleSubmitStep = async () => {
    const errors = await formikValidateFrom();
    const hasErrors = Object.keys(errors).some(field => Object.keys(insuranceHistoryValidationSchema).includes(field));

    if (hasErrors) {
      Object.keys(insuranceHistoryValidationSchema).forEach(field => formikSetTouched(field, true));
      return;
    }

    const formattedFields = {
      insuranceClaims: Number(formikValues.insuranceClaims),
      insuranceClaimInYear: formikValues.insuranceClaimInYear ? Number(formikValues.insuranceClaimInYear) : '',
    };
    setOffersFields({...formikValues, ...formattedFields});
    fireGtmEvent('homeInsurancePastConfirm', formattedFields);
    handleOtpOpen();
  };

  const renderError = (message) => (<FormHelperText error>{message}</FormHelperText>);

  return (
    <div className={className} id='vertical-tabpanel-1' role='tabpanel'>
      <FormControl component='fieldset' className={classes.marginBottom}>
        <FormLabel component='legend'><FormattedMessage id='insurance_history.title'/></FormLabel>
        <Grid item lg={12}>
          <Box
            component='div'
            display={{md: 'flex', xs: 'block'}}
            alignItems='center'
            className={classes.withClaimTooltip}
          >
            <RadioGroup
              name='insuranceClaims'
              value={Number(formikValues.insuranceClaims)}
              onChange={e => {
                formikHandleChange(e);
                fireGtmEvent('homeClaims', {claims: e.target.value});
              }}
              className={classes.radioGroup}
              row
              data-testid='insurance-claims-radio'
            >
              <FormControlLabel
                value={-1}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                label={<FormattedMessage id='insurance_history.label_no_claims'/>}
                data-testid='insurance-claims--1'
              />
              <FormControlLabel
                value={1}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                label={<FormattedMessage id='insurance_history.label_one'/>}
                data-testid='insurance-claims-1'
              />
              <FormControlLabel
                value={2}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                label={<FormattedMessage id='insurance_history.label_two_plus'/>}
                data-testid='insurance-claims-2'
              />
            </RadioGroup>
            {formikValues.insuranceClaims === '2' ?
              <AlwaysOnTooltip
                show={formikValues.insuranceClaims === '2'}
                className={classes.insuranceClaimTooltip}
                kind='Alert'
                showText
              >
                <FormattedMessage id='insurance_history.2_and_more_claims_tooltip'/>
              </AlwaysOnTooltip> : false
            }
          </Box>
          <ErrorMessage name='insuranceClaims' render={renderError}/>
        </Grid>
      </FormControl>
      <Box>
        <AnchorElement />
        <CallMePopover
          id={callMeid} anchorEl={document.querySelector('#popoverAnchor')}
          open={callMeOpen}
          onClose={handleCallMeClose}
          onSubmit={handleCallMeSubmit}
          message={callMeSubmitted ? <FormattedMessage id='insurance_history.call_me_message_success'/> :
            <FormattedMessage id='insurance_history.call_me_message'/>}
          callMeSubmitted={callMeSubmitted}
        />
      </Box>
      <div>
        {formikValues.insuranceClaims !== '1' && <Button
          disabled={formikValues.insuranceClaims === '2'}
          onClick={handleSubmitStep}
          data-testid='insurance-tab-submit'
          aria-label={submitButtonAriaLabel(formikErrors, formikValues.insuranceClaims)}>
          <FormattedMessage id='insurance_history.submit_button' />
          <Icon>arrow_back</Icon>
        </Button>}
      </div>
    </div>
  );
};

const AnchorElement = () => (<span style={{position: 'absolute',
  bottom: 20}} id='popoverAnchor'/>);

InsuranceHistoryStep.propTypes = {
  className: PropTypes.string,
  formikHandleChange: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  formikErrors: PropTypes.object,
  handleOtpOpen: PropTypes.func,
  history: PropTypes.object,
  isFormValid: PropTypes.bool,
  location: PropTypes.object,
};

export const insuranceHistoryValidationSchema = {
  insuranceClaims: yup.string().required('שדה חובה'),
  insuranceClaimInYear: yup.number().when('insuranceClaims', {
    is: '1',
    then: yup.number().required('שדה חובה'),
  }),
};

export default InsuranceHistoryStep;
