import React from 'react';
import PropTypes from 'prop-types';
import MainContainer from 'components/MainContainer';
import ScrollToTop from 'components/ScrollToTop';
import Routes from 'components/Routes';
import withStepGuardMiddleware from 'utils/StepGuardMiddleware';

function App({location}) {
  return (
    <MainContainer>
      <ScrollToTop>
        <Routes location={location} />
      </ScrollToTop>
    </MainContainer>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

export default withStepGuardMiddleware(App);

