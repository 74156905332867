export default {
  details: [
    {
      index: 0,
      key: 'home-info',
      label: 'פרטי נכס',
      gtmLabel: 'Property',
    },
    {
      index: 1,
      key: 'insurance-history',
      label: 'עבר ביטוחי',
      gtmLabel: 'PastInsurance',
    },
  ],
};
