import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Redirect} from 'react-router-dom';
import stepsGuards from '../config/stepsGuards';

function withStepGuardMiddleware(Component) {
  function WrappedComponent(props) {
    const {location} = props;
    const [hasAccess, setHasAccess] = useState(true);
    const [redirectTo, setRedirectTo] = useState('');

    useLayoutEffect(() => {
      const {hasAccess: newHasAccess, redirectTo: newRedirectTo} = checkAccess(location);
      if (newHasAccess !== hasAccess) {
        setHasAccess(newHasAccess);
      }
      if (!newHasAccess && redirectTo !== newRedirectTo) {
        setRedirectTo(newRedirectTo);
      }
    }, [location]);

    return hasAccess ? <Component {...props} /> : <Redirect to={redirectTo}/>;
  }

  WrappedComponent.propTypes = {
    location: PropTypes.any,
  };

  return withRouter(WrappedComponent);
}

const checkAccess = (location) => {
  const {pathname, hash} = location;
  const guard = stepsGuards.find(step => step.uri === `${pathname}${hash}`);

  if (!guard) {
    return {
      hasAccess: true,
    };
  }

  return {
    hasAccess: guard ? guard.check() : true,
    redirectTo: guard ? guard.redirectTo : undefined,
  };
};

export default withStepGuardMiddleware;
