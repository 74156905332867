import status from '../mock-data/status.json';
import offers from '../mock-data/offers.json';
import coversTitles from '../mock-data/covers-titles.json';
import updateCustomerSelectedOfferMock from '../mock-data/mock-updateCustomerSelectedOffer.json';

/**
 * Getting data for mocked API request
 *
 * @param {string} endpoint - for mocking the necessary request
 * @returns {object} mocked data
 */
export const localMocks = (endpoint) => {
  switch (endpoint) {
  case 'api/check_status':
    return status;
  case 'api/get_offers':
    return offers;
  case 'data/get_covers_titles':
    return coversTitles;
  case 'api/updateCustomerSelectedOffer':
    return updateCustomerSelectedOfferMock;
  default:
    return null;
  }
};
