import React from 'react';
import {injectIntl} from 'react-intl';
import propTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Grid, Button} from '@material-ui/core';
import {Breakpoints as breakpoints, Offer as GenericOffer} from 'wobi-web-common';
import companiesLogos from '../../assets/js/companiesLogos';
import PurchaseContainer from '../PurchaseContainer';
import useStyles from './useStyles';

const Offer = (props) => {
  const isXSmall = useMediaQuery('(max-width:420px)');
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const {data, isOpen, setIsOpen} = props;
  const classes = useStyles({
    isMobile,
    isOpen,
  });
  const [creditCardCommission, setCreditCardCommission] = React.useState(0);
  const {
    providerName,
    productLineName,
    price,
    protectionTitle,
  } = data;

  const messages = (id) => props.intl.formatMessage({id});
  const logo = companiesLogos.find(elm => elm.key === props.data.logo);
  const getLogoSource = () => {
    if (!logo) {
      return '';
    }

    return isXSmall ? logo.imgSrc_small : logo.imgSrc_big;
  };

  return (
    <GenericOffer {...props}>
      <div
        className={[classes.offerContent, isOpen ? '' : classes.offerContentClosed].join(' ')}
        onClick={isOpen ? undefined : () => setIsOpen(!isOpen)}
        onKeyPress={isOpen ? undefined : () => setIsOpen(!isOpen)}
      >
        {!isMobile || !isOpen ?
          <div className={[classes.logo, isOpen ? '' : classes.logoClosed].join(' ')}>
            <img
              src={logo ? logo.imgSrc_big : ''}
              alt={`חברת ${providerName}`}
              data-testid={isOpen ? 'open-offer-logo-img' : ''}
            />
            <b data-testid={isOpen ? 'open-offer-product-line-name' : ''}>{productLineName}</b>
          </div> : false
        }
        <div className={[classes.left, isOpen ? '' : classes.leftClosed].join(' ')}>
          <Grid
            container
            style={{position: 'relative'}}
            spacing={0}
            justify={isMobile ? 'flex-start' : 'space-between'}
          >
            {isMobile && isOpen ? (
              <div>
                <div className={[classes.logo, isOpen ? '' : classes.logoClosed].join(' ')}>
                  <img
                    src={getLogoSource()}
                    alt={`חברת ${providerName}`}
                    data-testid={isOpen ? 'open-offer-logo-img' : ''}
                  />
                  <b data-testid={isOpen ? 'open-offer-product-line-name' : ''}>{productLineName}</b>
                </div>
              </div>
            ) : false}
            {isMobile && isOpen ? <span className={classes.partition} /> : false}
            <Grid item xs={6} className={classes.pricesWrap}>
              <div className={[classes.prices, isOpen ? '' : classes.pricesClosed].join(' ')}>
                <div className={[classes.totalPrice, isOpen ? '' : classes.totalPriceClosed].join(' ')}>
                  <span>₪</span>
                  {Math.round(creditCardCommission + price).toLocaleString()}
                </div>
                {isOpen ? (
                  <div className={classes.partialPrices}>
                    {creditCardCommission ? (
                      <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                        {`${messages('offers.credit_card_commission')}
                       ${creditCardCommission.toLocaleString()} ₪`}
                      </span>
                    ) : false}
                  </div>
                ) : false}
              </div>
            </Grid>
            {isOpen ? false : (
              <Grid className={classes.purchaseBtnWrapper} item xs={6}>
                <Button
                  onClick={() => setIsOpen(!isOpen)}
                  color='secondary'
                  variant='outlined'
                  className={classes.purchaseBtn}
                  data-testid='offer-purchase-btn'
                >
                  {messages('offers.purchase')}
                </Button>
                {!isMobile && !isOpen && protectionTitle ? (
                  <div className={[classes.protectionWrapper, isOpen ? classes.protectionWrapperOpen : ''].join(' ')}>
                    <span className={classes.protection}>{protectionTitle}</span>
                  </div>
                ) : false}
              </Grid>
            )}
          </Grid>
          {isOpen ? (
            <PurchaseContainer
              classes={classes}
              creditCardCommission={creditCardCommission}
              setCreditCardCommission={setCreditCardCommission}
              openContactUs={props.openContactUs} data={data}
            />
          ) : false}
        </div>
      </div>
    </GenericOffer>
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  closeContactUsHandler: propTypes.func.isRequired,
  openContactUs: propTypes.func.isRequired,
};

export default injectIntl(Offer);
