export const getFieldsFromObject = (obj, fields) => {
  if (!obj || typeof obj !== 'object' || !fields || !Array.isArray(fields)) {
    return obj;
  }
  const result = {};
  Object.keys(obj).forEach(key => {
    if (fields.includes(key)) {
      result[key] = obj[key];
    }
  });
  return result;
};
