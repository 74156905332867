import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {GettingStartedRoute} from '../../config/routes';
import {fireGtmEvent} from '../../utils/marketingUtils';
import useStyles from './useStyles';

const MainTitle = ({mainText, subText}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainText}>{mainText}</div>
      {subText ?
        <div className={classes.subText}>
          <Link to={GettingStartedRoute.pathname} onClick={() => fireGtmEvent('homeBackDate')}>
            {subText}
          </Link>
        </div> : null
      }
    </div>
  );
};

MainTitle.propTypes = {
  mainText: PropTypes.node,
  subText: PropTypes.node,
};

export default MainTitle;
