import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Button, TextField, MenuItem, FormHelperText, CircularProgress, Grid} from '@material-ui/core';
import {Breakpoints as breakpoints, EzFormControl, Tooltip, YesNoRadioGroup} from 'wobi-web-common';
import {validIsraeliId} from 'wobi-web-common/dist/components/utils/validation';
import HelpIcon from '@material-ui/icons/Help';
import {getOffersFields, setOffersFields, setSelectedOffer} from '../../utils/persistOfferDetails';
import {utils} from '../../utils/offerFieldsActions';
import TelephoneIcon from '../../assets/images/telephone.svg';
import {verifyPayment, updateCustomerSelectedOffer, handleContactUsSubmit} from '../../utils/apiHandlers';
import {ORDER_ID} from '../../consts/storageKeys';
import {fireGtmEvent} from '../../utils/marketingUtils';
import {CONTACT_US_SOURCES} from '../../consts';
import useStyles from './useStyles';

const PurchaseContainer = injectIntl(withRouter(({intl, data, history, openContactUs, setCreditCardCommission,
  creditCardCommission,
}) => {
  const messages = (id) => intl.formatMessage({id});
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const yearsOptions = utils.getYearsOptions();
  const monthsOptions = utils.getMonthsOptions();
  const classes = useStyles({isMobile});

  // State
  const [showAllForm, setShowAllForm] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const [freeOfInterest, setFreeOfInterest] = React.useState(null);
  const [creditInterest, setCreditInterest] = React.useState([]);
  const [isContactLoading, setIsContactLoading] = React.useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = React.useState(false);

  const creditCardNumber_class = (formShowAll, errors_creditCardNumber, touched_creditCardNumber) => {
    if (!formShowAll) {
      return 'init';
    }
    if (!errors_creditCardNumber) {
      return 'helperText';
    }
    if (showAllForm && errors_creditCardNumber && touched_creditCardNumber) {
      return 'helperText err';
    }
    return '';
  };

  const showCallUsButton = () => {
    if (isContactLoading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className='contact_us_loader' size={20} />
        </div>
      );
    }
    return (
      <Button
        variant='text'
        className={classes.callUs}
        onClick={submitContactUs}
        data-testid='offer-contact-me-btn'
      >
        <img src={TelephoneIcon} alt='call-us'/>
        <strong className={classes.callUsStrong}>{messages('offers.debating')}</strong>
        {messages('offers.call_us')}
      </Button>
    );
  };

  const handleSelectedOffer = async () => {
    setSelectedOffer(data);
    const resp = await updateCustomerSelectedOffer({
      selectedOffer: data,
      token: getOffersFields('offersCallId'),
    });
    setFreeOfInterest(resp.data?.freeOfInterest);
    setCreditInterest(resp.data?.creditInterest);
    generatePayments(resp.data);
  };

  const extendForm = () => {
    handleSelectedOffer();
    setShowAllForm(true);
  };

  const submitContactUs = async () => {
    setIsContactLoading(true);
    try {
      await handleSelectedOffer();
      fireGtmEvent('homeOfferContactUs', {provider: data.provider});
      await handleContactUsSubmit(getOffersFields(), CONTACT_US_SOURCES.OFFER);
      openContactUs();
      setIsContactLoading(false);
    } catch (error) {
      setIsContactLoading(false);
      console.log(error);
    }
  };

  const generatePayments = (selectedOfferData) => {
    if (!selectedOfferData) {
      return;
    }
    const items = [];
    for (let i = 0; i < selectedOfferData.creditInterest.length; i++) {
      items.push(i + 1);
    }
    setPayments(items);
  };

  const handlePaymentChange = (value, setFieldValue) => {
    setFieldValue('payment', value);
    if (Number(freeOfInterest) < Number(value)) {
      // eslint-disable-next-line max-len
      const creditInterestCalc = Math.round(data.price * creditInterest[Number(value) - 1] / 100);
      setCreditCardCommission(creditInterestCalc);
    } else {
      setCreditCardCommission(0);
    }
  };

  const onSubmit = async (values, {setErrors}) => {
    const phone = getOffersFields('phone');
    const requestData = {
      ...values,
      creditInterest: creditInterest[Number(values.payment) - 1],
      month: String(values.month).padStart(2, '0'),
      offersCallId: getOffersFields('offersCallId'),
      orderId: sessionStorage.getItem(ORDER_ID),
      payment: String(values.payment),
      totalPrice: creditCardCommission ? Math.round(creditCardCommission + data.price) : '',
      year: String(values.year),
      policyOwnerIsraeliId: values.policyOwnerIsraeliId ? values.policyOwnerIsraeliId : values.identification,
      phone,
    };
    setIsVerifyingPayment(true);
    try {
      const resp = await verifyPayment(requestData);
      setIsVerifyingPayment(false);
      if (!resp || !resp.success) {
        setErrors({creditCardNumber: messages('validation.payment_submit_error')});
      } else {
        // add action
        setOffersFields({potentialId: resp.data.id});
        fireGtmEvent('homeConfirmOrder', {provider: data.providerName});
        history.push('/thank-you/');
      }
    } catch {
      setIsVerifyingPayment(false);
      setErrors({creditCardNumber: messages('validation.payment_submit_error')});
    }
  };

  return (
    <div className={classes.purchaseContainer}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          cardHolder: yup.string().required(messages('validation.required')),
          creditCardNumber: yup.number()
            .typeError(messages('validation.number_not_valid'))
            .required(messages('validation.required')),
          cvv: yup.number()
            .typeError(messages('validation.number_not_valid'))
            .required(messages('validation.required')),
          identification: validIsraeliId,
          month: yup.string().required(messages('validation.required')),
          payment: yup.string().required(messages('validation.required')),
          year: yup.string().required(messages('validation.required')),
          isOwnerPolicyIsOwnerCreditCard: yup.string(),
          policyOwnerIsraeliId: yup.mixed().when('isOwnerPolicyIsOwnerCreditCard', {
            is: (val) => val === 'no',
            then: validIsraeliId,
          }),
        })}
        initialValues={{
          cardHolder: `${getOffersFields('firstname')} ${getOffersFields('lastname')}`,
          creditCardNumber: '',
          creditCardType: '',
          cvv: '',
          identification: '',
          month: '',
          payment: '',
          year: '',
          isOwnerPolicyIsOwnerCreditCard: 'yes',
          policyOwnerIsraeliId: '',
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className={`${classes.form} ${showAllForm ? '' : 'init'}`} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {isMobile && showAllForm || !isMobile ? (
                <Grid
                  item
                  xs={12}
                  md={showAllForm ? 12 : 6}
                  lg={6}
                  className={creditCardNumber_class(showAllForm, errors.creditCardNumber, touched.creditCardNumber)}
                >
                  <EzFormControl
                    name='creditCardNumber'
                    label={messages('fields.credit_card_number')}
                    labelFor={`creditCardNumber-${data.productLineId}`}
                    isLabelInChild
                  >
                    <TextField
                      id={`creditCardNumber-${data.productLineId}`}
                      onFocus={() => {
                        extendForm();
                        fireGtmEvent('homeFillCreditNumber', {provider: data.provider});
                      }}
                      size='small'
                      onChange={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        handleChange(e);
                      }}
                      fullWidth
                      InputLabelProps={{className: classes.inputLabel}}
                    />
                  </EzFormControl>
                  {showAllForm ? (
                    <FormHelperText className={classes.creditCardHelp}>
                      {messages('fields.credit_card_number.helpText')}
                    </FormHelperText>
                  ) : false}
                </Grid>
              ) : false}
              {!showAllForm && (
                <Grid item xs={12} md={6} className={classes.paymentBtnInitWrapper}>
                  <Button
                    className={`${classes.paymentBtn} init`}
                    onClick={() => {
                      extendForm();
                      fireGtmEvent('homeOfferPurchaseButton', {provider: data.provider});
                    }}
                    data-testid='offer-purchase-btn'
                  >
                    {messages('offers.payment_btn')}
                  </Button>
                  {showCallUsButton()}
                  {isMobile && data.protectionTitle ? (
                    <div className={`${classes.protectionWrapper} open`}>
                      <span className={classes.protection}>{data.protectionTitle}</span>
                    </div>
                  ) : false}
                </Grid>
              )}
              {showAllForm && (
                <React.Fragment>
                  <Grid item xs={4} sm={6} lg={3}>
                    <EzFormControl
                      name='year'
                      label={messages('fields.card_year')}
                      labelFor='creditcard-year'
                      isLabelInChild
                    >
                      <TextField
                        select
                        id='creditcard-year'
                        value={values.year}
                        onChange={(e) => setFieldValue('year', e.target.value)}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel, id: 'year-label'}}
                      >
                        {yearsOptions.map(year => <MenuItem key={`year-${year}`} value={year}>{year}</MenuItem>)}
                      </TextField>
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={4} sm={6} lg={3}>
                    <EzFormControl
                      name='month'
                      label={messages('fields.card_month')}
                      labelFor='creditcard-month'
                      isLabelInChild
                    >
                      <TextField
                        select
                        id='creditcard-month'
                        value={values.month}
                        onChange={(e) => setFieldValue('month', e.target.value)}
                        fullWidth
                        size='small'
                        InputLabelProps={{className: classes.inputLabel, id: 'month-label'}}
                      >
                        {monthsOptions.map(month => <MenuItem key={`month-${month}`} value={month}>{month}</MenuItem>)}
                      </TextField>
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={4} sm={6} lg={2}>
                    <EzFormControl
                      name='cvv'
                      label={messages('fields.cvv')}
                      labelFor={`cvv-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`cvv-${data.productLineId}`}
                        onChange={handleChange}
                        inputProps={{maxLength: '4'}}
                        size='small'
                        fullWidth
                        onFocus={() => {
                          fireGtmEvent('homeFillCVV', {provider: data.provider});
                        }}
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={5}>
                    <EzFormControl
                      name='cardHolder'
                      label={messages('fields.cardHolder')}
                      labelFor={`cardHolder-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`cardHolder-${data.productLineId}`}
                        onChange={handleChange}
                        value={values.cardHolder}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={5}>
                    <EzFormControl
                      name='identification'
                      label={messages('fields.identification')}
                      labelFor={`identification-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`identification-${data.productLineId}`}
                        onChange={handleChange}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} className={classes.yesNoGroupParent}>
                    <div className={classes.yesNoGroup}>
                      <EzFormControl
                        name='isOwnerPolicyIsOwnerCreditCard'
                        label={intl.formatMessage({id: 'details.is_owner_policy_is_owner_credit_card'})}
                      >
                        <YesNoRadioGroup
                          value={values.isOwnerPolicyIsOwnerCreditCard}
                          onChange={handleChange}
                        />
                      </EzFormControl>
                      {
                        values.isOwnerPolicyIsOwnerCreditCard === 'no' ? (
                          <Grid item xs={12} sm={12} lg={12}>
                            <EzFormControl
                              name='policyOwnerIsraeliId'
                              label={messages('fields.owner_policy_israeli_id')}
                              labelFor={`policyOwnerIsraeliId-${data.productLineId}`}
                              isLabelInChild
                            >
                              <TextField
                                id={`policyOwnerIsraeliId-${data.productLineId}`}
                                onChange={handleChange}
                                size='small'
                                InputLabelProps={{className: classes.inputLabel}}
                                className={classes.noOwner}
                              />
                            </EzFormControl>
                          </Grid>) : null
                      }
                    </div>

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <EzFormControl
                      name='payment'
                      isLabelInChild
                      label={messages('fields.payments')}
                      labelFor='creditcard-payment'
                    >
                      <TextField
                        select
                        id='creditcard-payment'
                        value={values.payment}
                        onChange={(e) => handlePaymentChange(e.target.value, setFieldValue)}
                        style={{
                          flex: '1 0 auto',
                          minWidth: 135,
                        }}
                        size='small'
                        InputLabelProps={{className: classes.inputLabel}}
                      >
                        {payments.map(item => <MenuItem key={`payment-${item}`} value={item}>{item}</MenuItem>)}
                      </TextField>
                      <Tooltip
                        placement='bottom'
                        title={`עד ${
                          freeOfInterest
                        } תשלומים ללא ריבית, מעל ${
                          freeOfInterest
                        } תשלומים יתווספו דמי אשראי ע״פ תנאי חברת ${
                          data.providerName
                        }`}
                      >
                        <span className={classes.tooltipWrap}>
                          <HelpIcon style={{height: 20,
                            width: 20}} className={classes.tooltip} />
                        </span>
                      </Tooltip>
                    </EzFormControl>
                    {isMobile ? false : (
                      <div className={classes.securedPayment}>
                        <div className={classes.securedPaymentInner}>{messages('offers.secured')}</div>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} className={classes.purchaseBtnWrapper}>
                    <Button
                      className={classes.paymentBtn}
                      type='submit'
                      disabled={isVerifyingPayment}
                      data-testid='offer-purchase-btn'
                    >
                      {messages('offers.payment_end_btn')}
                    </Button>
                    {showCallUsButton()}
                    {isVerifyingPayment ? <CircularProgress className={classes.verifyLoader} size={30} /> : false}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}));

export default PurchaseContainer;
