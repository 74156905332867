import {makeStyles} from '@material-ui/core';
import {Breakpoints} from 'wobi-web-common';

const useStyles = makeStyles((theme) => ({
  datepickerError: {
    marginBottom: 5,
    marginTop: 0,
  },
  paper: {
    boxShadow: 'unset',
  },
  preDefineDates: {
    marginTop: 20,
  },
  root: {
    '& .form-container': {
      minHeight: 354,
      minWidth: 272,
    },
    '& .img-container': {
      '& > img': {
        maxWidth: 500,
        width: '100%',
      },
      alignSelf: 'flex-end',
      padding: '20px 45px 20px 0',
      textAlign: 'center',
      [`@media screen and (max-width: ${Breakpoints.mobile}px)`]: {padding: '10px'},
      width: '100%',
    },
    '@media screen and (max-width: 920px)': {
      alignItems: 'center',
      flexDirection: 'column',
    },
    alignItems: 'flex-start',
    display: 'flex',
    height: 'inherit',
  },
  submitBtn: {
    fontSize: 18,
    height: 52,
    marginTop: 30,
    width: '100%',
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginBottom: 10,
    marginTop: 12,
    paddingLeft: 30,
    paddingRight: 10,
  },
  title: {
    '& > span': {
      color: theme.palette.text.secondary,
      fontSize: 21,
    },
    '& h1': {
      color: theme.palette.secondary.main,
      fontSize: 56,
      fontWeight: 300,
      lineHeight: 1,
      margin: 0,
    },
  },
  tooltip: {
    marginTop: 7,
    position: 'absolute',
  },
  tooltipOverride: {
    '@media screen and (max-width: 920px)': {
      left: 0,
      position: 'relative',
      top: 8,
    },
    alignItems: 'center',
    height: 35,
    left: 280,
    position: 'absolute',
    top: 21,
  },
  wrapper: {
    '&>.MuiFormControl-root': {
      '& .Mui-error': {
        position: 'absolute',
        top: 54,
      },
      marginTop: 12,
    },
    position: 'relative',
    width: 272,
  },
}));

export default useStyles;
