import {getOffersFields} from '../utils/persistOfferDetails';

export default [
  {
    index: 0,
    key: 'details',
    label: 'פרטים',
    guard: () => true,
  },
  {
    index: 1,
    key: 'offers',
    label: 'הצעות',
    guard: () => {
      const details = getOffersFields();
      return Boolean(details.residenceType && details.insuranceClaims);
    },
  },
  {
    index: 2,
    key: 'payment',
    label: 'תשלום וסיום',
    guard: () => false,
  },
];
