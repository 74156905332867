import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {NumberSlider, EzFormControl, Tooltip, YesNoRadioGroup, AlwaysOnTooltip} from 'wobi-web-common';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import {FormattedMessage, injectIntl} from 'react-intl';
import {FormHelperText, Icon, Button,
  TextField, InputAdornment, RadioGroup,
  Radio, FormControlLabel, FormControl, FormLabel, Select, MenuItem} from '@material-ui/core';
import {ErrorMessage} from 'formik';
import * as yup from 'yup';
import ResidenceTypeSelector from 'components/ResidenceTypeSelector';
import cities from 'config/cities';
import {OFFERS_FIELDS_KEY} from 'consts/storageKeys';
import {getOffersFields, setOffersFields} from 'utils/persistOfferDetails';
import ProductType from 'consts/product-type';
import {fireGtmEvent} from 'utils/marketingUtils';
import {throttle} from 'utils/funcUtils';
import {getFieldsFromObject} from 'utils/objectUtils';
import {InfoIcon} from 'assets/js/icons';
import useStyles, {CUSTOM_MAX_WIDTH} from './useStyles';

const throttledFireGtmEvent = throttle(fireGtmEvent, 2000);

const handleCitySelectChange = (value) => {
  if (value.length < 1) {
    return [];
  }
  return cities.filter(item => item.name.slice(0, value.length) === value).map(item => Object.assign(item, {
    label: item.name,
    value: item.code,
  }));
};

const getCityFields = () => {
  const {city, cityCode, cityPostCode} = JSON.parse(sessionStorage.getItem(OFFERS_FIELDS_KEY)) || {};

  if (!city || !Number.isFinite(cityCode) || !Number.isFinite(cityPostCode)) {
    return null;
  }

  return {
    cityCode,
    cityPostCode,
    label: city,
  };
};

const InfoTab = ({
  intl,
  formikValues,
  formikSetField,
  formikHandleChange,
  formikErrors,
  history,
  formikValidateFrom,
  formikSetTouched,
  className,
}) => {
  const [policyType, setPolicyType] = React.useState(getOffersFields('policyType'));

  React.useEffect(() => {
    setPolicyType(getOffersFields('policyType'));
  }, []);

  const classes = useStyles();
  const setCityFields = (name, code, post_code) => {
    formikSetField('city', name);
    formikSetField('cityCode', code);
    formikSetField('cityPostCode', Number(post_code));
  };
  const submitButtonAriaLabel = () => {
    if (formikValues.isBuiltFromBlocks === 'no') {
      return `${intl.formatMessage({id: 'details.submit_home_info'})}. ${intl.formatMessage({id:
        'details.building_materials_warning'})}`;
    } else if (formikValues.isOffice === 'yes') {
      return `${intl.formatMessage({id: 'details.submit_home_info'})}. ${intl.formatMessage({id:
        'details.is_office_warning'})}`;
    } else if (formikErrors && Object.keys(formikErrors).length) {
      return intl.formatMessage({id: 'details.validation_submit_ariaLabel'});
    } else {
      return null;
    }
  };

  const handleCityChange = (cityObject) => {
    if (!cityObject || !cityObject.name) {
      setCityFields('', '', '');
    } else {
      setCityFields(cityObject.name, cityObject.code, cityObject.post_code);
    }
  };

  const handleSquareMeters = (value) => {
    let newValue = value;
    newValue = newValue.replace(/[^0-9.]/g, '');
    formikSetField('squareMeters', newValue);
  };

  const handleSubmitStep = async () => {
    const errors = await formikValidateFrom();
    const hasErrors = Object.keys(errors).some(field => Object.keys(propertyDetailsValidationSchema).includes(field));

    if (hasErrors) {
      Object.keys(propertyDetailsValidationSchema).forEach(field => formikSetTouched(field, true));
      return;
    }

    setOffersFields(formikValues);
    const thisStepValues = getFieldsFromObject(formikValues, [
      'residenceType',
      'apartmentFloor',
      'buildingFloors',
      'squareMeters',
      'buildingAge',
      'city',
      'cityCode',
      'cityPostCode',
      'residentsNumber',
      'isMortgage',
      'isBuiltFromBlocks',
      'isOffice',
    ]);
    fireGtmEvent('homeConfirmProperty', thisStepValues);
    history.push('#insurance-history');
  };

  return (
    <div className={className} id='vertical-tabpanel-0' role='tabpanel'>
      <ResidenceTypeSelector
        value={formikValues.residenceType}
        onChange={e => {
          formikHandleChange(e);
          if (e.target.value) {
            fireGtmEvent(`homeSelected${e.target.value.toUpperCase()}`);
          }
        }}
      />
      <FormHelperText className={classes.error} error data-testid='residenceType-error-text'>
        <ErrorMessage name='residenceType' />
      </FormHelperText>
      {formikValues.residenceType === 'apartment' && (
        <div>
          <div data-testid='apartment-floor-input'>
            <EzFormControl
              name='apartmentFloor'
              titleOnLabel={intl.formatMessage({id: 'details.apartment_floor.titleOnLabel'})}
              label={intl.formatMessage({id: 'details.apartment_floor'})}
              overriddenStyles={{inlineLabel: {justifyContent: 'space-between', maxWidth: CUSTOM_MAX_WIDTH}}}
            >
              <NumberSlider
                value={formikValues.apartmentFloor}
                handleChange={val => formikSetField('apartmentFloor', val)}
                min={0}
                max={formikValues.buildingFloors}
              />
            </EzFormControl>
          </div>
          <div data-testid='building-floors-input'>
            <EzFormControl
              name='buildingFloors'
              label={intl.formatMessage({id: 'details.building_floors'})}
              overriddenStyles={{inlineLabel: {justifyContent: 'space-between', maxWidth: CUSTOM_MAX_WIDTH}}}
            >
              <NumberSlider
                value={formikValues.buildingFloors}
                handleChange={val => {
                  formikSetField('buildingFloors', val);
                  if (formikValues.apartmentFloor > val) {
                    formikSetField('apartmentFloor', val);
                  }
                }}
                min={0}
                max={100}
              />
            </EzFormControl>
          </div>
          <FormHelperText>
            <FormattedMessage id='details.building_floor_hint' />
          </FormHelperText>
        </div>
      )}
      {policyType !== ProductType.Content && (
        <div className={`${classes.marginBottom} ${classes.marginTop} ${classes.customWidth}`}>
          <EzFormControl
            name='squareMeters'
            isLabelInChild
            label={intl.formatMessage({id: 'details.apartment_area'})}
            ariaLabel={intl.formatMessage({id: 'details.apartment_area'}) + '. ' +
                    intl.formatMessage({id: 'details.apartment_area_help'})}
          >
            <TextField
              fullWidth
              variant='standard'
              value={formikValues.squareMeters}
              name='squareMeters'
              id='squareMeters'
              onChange={e => handleSquareMeters(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment
                  position='end'
                  className={classes.infoIcon}
                >
                  <Tooltip
                    placement='top'
                    title={intl.formatMessage({id: 'details.apartment_area_help'})}
                    tooltipProps={{
                      onOpen: () => {
                        throttledFireGtmEvent('homeSquareMetersMoreInfo');
                      },
                    }}
                  >
                    <InfoIcon/>
                  </Tooltip>
                </InputAdornment>,
              }}
              inputProps={{
                'data-testid': 'squareMeters-input',
              }}
            />
          </EzFormControl>
        </div>
      )}
      <FormControl component='fieldset' className={classes.marginBottom} fullWidth>
        <FormLabel component='legend' className={classes.radioGroupLegend}>
          <FormattedMessage id='details.building_age'/>
        </FormLabel>
        <RadioGroup
          name='buildingAge'
          value={formikValues.buildingAge}
          onChange={e => {
            formikHandleChange(e);
            fireGtmEvent('homeBuildingAge', {buildingAge: e.target.value});
          }}
          row
          data-testid='building-age-radio'
          className={classes.customWidth}
        >
          <FormControlLabel
            value='0'
            control={<Radio inputProps={{'aria-setsize': '3'}}/>}
            label={<FormattedMessage id='details.label_new_building'/>}
            data-testid='building-age-0'
          />
          <FormControlLabel
            value='6'
            control={<Radio inputProps={{'aria-setsize': '3'}}/>}
            label={<FormattedMessage id='details.label_5_years_building'/>}
            data-testid='building-age-6'
          />
          <FormControlLabel
            value='21'
            control={<Radio inputProps={{'aria-setsize': '3'}}/>}
            label={<FormattedMessage id='details.label_20_years_building'/>}
            data-testid='building-age-21'
          />
        </RadioGroup>
        <FormHelperText className={classes.error} error data-testid='buildingAge-error-text'>
          <ErrorMessage name='buildingAge' />
        </FormHelperText>
      </FormControl>
      <div className={`${classes.marginBottom} ${classes.customWidth}`}>
        <EzFormControl name='city'>
          <AutocompleteSingleSelect
            inputLabel={intl.formatMessage({id: 'details.city'})}
            parentValue={formikValues.city}
            placeholder={intl.formatMessage({id: 'details.city'})}
            onInput={handleCitySelectChange}
            onSelect={handleCityChange}
            defaultValue={getCityFields()}
            id='city-autocomplete'
          />
        </EzFormControl>
      </div>
      {policyType !== ProductType.Structure && (
        <div className={`${classes.marginBottom} ${classes.customWidth}`}>
          <EzFormControl
            name='residentsNumber'
            label={intl.formatMessage({id: 'details.residents_number'})}
            labelFor='select-residents-number'
          >
            <Select
              id='select-residents-number'
              labelId='year-label'
              fullWidth
              value={formikValues.residentsNumber}
              onChange={e => {
                formikHandleChange(e);
                fireGtmEvent('homeResidentsNumber', {residentsNumber: e.target.value});
              }}
            >
              {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'].map(num => (
                <MenuItem key={`num-${num}`} value={num.match(/(\d+)/)[0]}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </EzFormControl>
        </div>
      )}
      {policyType !== ProductType.Content && (
        <div>
          <div className={classes.yesNoGroup}>
            <EzFormControl name='isMortgage' label={intl.formatMessage({id: 'details.is_mortgage'})} isLabelInChild>
              <YesNoRadioGroup
                value={formikValues.isMortgage}
                onChange={e => {
                  formikHandleChange(e);
                  fireGtmEvent('homeIsMortgage', {isMortgage: e.target.value});
                }}
              />
            </EzFormControl>
          </div>
          <div className={classes.yesNoGroup}>
            <EzFormControl
              name='isBuiltFromBlocks'
              label={intl.formatMessage({id: 'details.building_materials'})}
              isLabelInChild
            >
              <YesNoRadioGroup
                value={formikValues.isBuiltFromBlocks}
                onChange={e => {
                  formikHandleChange(e);
                  fireGtmEvent('homeIsBuiltFromBlocks', {isBuiltFromBlocks: e.target.value});
                }}
              />
              <AlwaysOnTooltip
                show={formikValues.isBuiltFromBlocks === 'no'}
                kind='Alert'
                showText
                bubbleClassName={classes.materialsTooltipBubble}
                className={classes.materialsTooltip}
              >
                <FormattedMessage id='details.building_materials_warning' />
              </AlwaysOnTooltip>
            </EzFormControl>
          </div>
          <div className={classes.yesNoGroup}>
            <EzFormControl name='isOffice' label={intl.formatMessage({id: 'details.is_office'})} isLabelInChild>
              <YesNoRadioGroup
                value={formikValues.isOffice}
                onChange={e => {
                  formikHandleChange(e);
                  fireGtmEvent('homeIsOffice', {isOffice: e.target.value});
                }}
              />
              <AlwaysOnTooltip
                show={formikValues.isOffice === 'yes'}
                kind='Alert'
                showText
                bubbleClassName={classes.materialsTooltipBubble}
                className={classes.materialsTooltip}
              >
                <FormattedMessage id='details.is_office_warning' />
              </AlwaysOnTooltip>
            </EzFormControl>
          </div>
        </div>
      )}
      <Button
        disabled={formikValues.isBuiltFromBlocks === 'no' || formikValues.isOffice === 'yes'}
        onClick={handleSubmitStep}
        data-testid='info-tab-submit'
        aria-label={submitButtonAriaLabel()}>
        <FormattedMessage id='details.submit_home_info' />
        <Icon>arrow_back</Icon>
      </Button>
    </div>
  );
};

InfoTab.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  formikErrors: PropTypes.object,
  history: PropTypes.object,
};

export const propertyDetailsValidationSchema = {
  residenceType: yup.string().required('שדה חובה'),
  apartmentFloor: yup.number().when('residenceType', {
    is: val => val === 'apartment',
    then: yup.number().required('שדה חובה'),
  }),
  buildingFloors: yup.number().when('residenceType', {
    is: val => val === 'apartment',
    then: yup.number().required('שדה חובה'),
  }),
  squareMeters: yup.number().typeError('שטח הנכס לא תקין, אנא הקלד מספר').test('required-if-not-content', 'שדה חובה',
    value => getOffersFields('policyType') === ProductType.Content || value),
  buildingAge: yup.string().required('שדה חובה'),
  city: yup.string().required('שדה חובה'),
  residentsNumber: yup.string().test('required-if-not-structure', 'שדה חובה',
    value => getOffersFields('policyType') === ProductType.Structure || value),
  isMortgage: yup.string().test('required-if-not-content', 'שדה חובה',
    value => getOffersFields('policyType') === ProductType.Content || value),
  isBuiltFromBlocks: yup.string().test('required-if-not-content', 'שדה חובה',
    value => getOffersFields('policyType') === ProductType.Content || value),
  isOffice: yup.string().test('required-if-not-content', 'שדה חובה',
    value => getOffersFields('policyType') === ProductType.Content || value),
};

export default injectIntl(withRouter(InfoTab));
